const worksheets = [
    {
        id: "cashEstimate",
        label: "Cash Estimate",
        estimates: [
            {
                id: "cashEstimate",
                label: "Cash Estimate",
                value: false,
                type: ["multiChoice", "input"],
                options: [
                    { label: "True", value: true },
                    { label: "False", value: false },
                ],
            },
            { id: "subTotal", label: "Subtotal", value: 0, type: ["currency"] },
            {
                id: "costPerSq",
                label: "Cost Per Square",
                value: 0,
                type: ["currency"],
            },
            {
                id: "warrantySold",
                label: "Warranty Sold",
                value: 1000,
                type: ["currency", "input"],
            },
            {
                id: "desiredProfitMargin",
                label: "Desired Profit Margin",
                value: 0.42,
                type: ["percent", "input"],
            },
            {
                id: "pricePerSq",
                label: "Price Per Square",
                value: 0,
                type: ["currency"],
            },
            {
                id: "totalPrice",
                label: "Total Price",
                value: 0,
                type: ["currency"],
            },
            { id: "profit", label: "Profit", value: 0, type: ["currency"] },
            {
                id: "marginBeforeCommission",
                label: "Margin Before Commission",
                value: 0,
                type: ["percent"],
            },
            {
                id: "commission",
                label: "Commission Amount",
                value: 0,
                type: ["currency"],
            },
            {
                id: "marginAfterCommission",
                label: "Margin After Commission",
                value: 0,
                type: ["percent"],
            },
            {
                id: "totalProjectCost",
                label: "Total Project Cost",
                value: 0,
                type: ["currency"],
            },
        ],
    },
    {
        id: "insuranceWork",
        label: "Insurance Work",
        estimates: [
            {
                id: "acvCheck",
                label: "ACV Check",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "recoverableDepreciationAndTaxes",
                label: "Recoverable Depreciation & Taxes",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "deductible",
                label: "Deductible",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "totalInsuranceRCV",
                label: "Total Insurance RCV",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "roofRCV",
                label: "Roof RCV",
                value: 0,
                type: ["currency"],
            },
            {
                id: "workNotDoing",
                label: "Work Not Doing (OOS)",
                value: 0,
                type: ["currency"],
            },
            {
                id: "supplements",
                label: "Supplements",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "oAndP",
                label: "O&P",
                value: 0,
                type: ["currency"],
            },
            {
                id: "warrantySold",
                label: "Warranty Sold",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "addlOutOfPocketExpenses",
                label: "Additional Out of Pocket Expenses",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "profit",
                label: "Profit",
                value: 0,
                type: ["currency"],
            },
            {
                id: "marginBeforeCommission",
                label: "Margin Before Commission",
                value: 0,
                type: ["percent"],
            },
            {
                id: "commission",
                label: "Commission Amount",
                value: 0,
                type: ["currency"],
            },
            {
                id: "marginAfterCommission",
                label: "Margin After Commission",
                value: 0,
                type: ["percent"],
            },
            {
                id: "totalProjectCost",
                label: "Total Project Cost",
                value: 0,
                type: ["currency"],
            },
        ],
    },
    {
        id: "outOfScope",
        label: "Out of Scope",
        estimates: [
            {
                id: "line1",
                label: "Line #1",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line2",
                label: "Line #2",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line3",
                label: "Line #3",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line4",
                label: "Line #4",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line5",
                label: "Line #5",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line6",
                label: "Line #6",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line7",
                label: "Line #7",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line8",
                label: "Line #8",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line9",
                label: "Line #9",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line10",
                label: "Line #10",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "oosTotal",
                label: "OOS Total",
                value: 0,
                type: ["currency"],
            },
        ],
    },
    {
        id: "overhead",
        label: "Total Project Overhead",
        estimates: [
            {
                id: "line1",
                label: "Line #1",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line2",
                label: "Line #2",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line3",
                label: "Line #3",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line4",
                label: "Line #4",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line5",
                label: "Line #5",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line6",
                label: "Line #6",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line7",
                label: "Line #7",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line8",
                label: "Line #8",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line9",
                label: "Line #9",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "line10",
                label: "Line #10",
                value: 0,
                type: ["currency", "input"],
            },
            {
                id: "oAndPTotal",
                label: "Total In Scope O&P",
                value: 0,
                type: ["currency"],
            },
            {
                id: "oosOAndPTotal",
                label: "Total OOS O&P",
                value: 0,
                type: ["currency"],
            },
        ],
    },
];

const worksheetResolvers = [
    {
        id: "cashEstimate",
        resolvers: [
            {
                id: "subTotal",
                resolver({ materialsEstimateTotal, laborEstimateTotal }) {
                    return materialsEstimateTotal + laborEstimateTotal;
                },
            },
            {
                id: "costPerSq",
                resolver({
                    materialsEstimateTotal,
                    laborEstimateTotal,
                    squares,
                }) {
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    return subTotal / squares;
                },
            },
            {
                id: "pricePerSq",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                    squares,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    let totalPrice = subTotal / (1 - desiredProfitMargin);
                    return totalPrice / squares;
                },
            },
            {
                id: "totalPrice",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    return subTotal / (1 - desiredProfitMargin);
                },
            },
            {
                id: "profit",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    let totalPrice = subTotal / (1 - desiredProfitMargin);
                    return totalPrice - subTotal;
                },
            },
            {
                id: "marginBeforeCommission",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    let totalPrice = subTotal / (1 - desiredProfitMargin);
                    let profit = totalPrice - subTotal;
                    return profit / totalPrice;
                },
            },
            {
                id: "commission",
                resolver() {
                    return 0;
                },
            },
            {
                id: "marginAfterCommission",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    let totalPrice = subTotal / (1 - desiredProfitMargin);
                    let profit = totalPrice - subTotal;
                    let commission = 0;
                    let totalProjectCost = subTotal / (1 - desiredProfitMargin);
                    return (profit - commission) / totalProjectCost;
                },
            },
            {
                id: "totalProjectCost",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    let desiredProfitMargin =
                        estimate.cashEstimate.desiredProfitMargin;
                    let subTotal = materialsEstimateTotal + laborEstimateTotal;
                    return subTotal / (1 - desiredProfitMargin);
                },
            },
        ],
    },
    {
        id: "insuranceWork",
        resolvers: [
            {
                id: "roofRCV",
                resolver({ estimate }) {
                    return roofRCVResolver({ estimate });
                },
            },
            {
                id: "workNotDoing",
                resolver: oosTotalResolver,
            },
            {
                id: "oAndP",
                resolver({ estimate }) {
                    return oAndPResolver({ estimate });
                },
            },
            {
                id: "profit",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    return profitResolver({
                        estimate,
                        materialsEstimateTotal,
                        laborEstimateTotal,
                    });
                },
            },
            {
                id: "marginBeforeCommission",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    return marginBeforeCommissionResolver({
                        estimate,
                        materialsEstimateTotal,
                        laborEstimateTotal,
                    });
                },
            },
            {
                id: "commission",
                resolver() {
                    return commissionResolver();
                },
            },
            {
                id: "marginAfterCommission",
                resolver({
                    estimate,
                    materialsEstimateTotal,
                    laborEstimateTotal,
                }) {
                    return marginAfterCommissionResolver({
                        estimate,
                        materialsEstimateTotal,
                        laborEstimateTotal,
                    });
                },
            },
            {
                id: "totalProjectCost",
                resolver({ estimate }) {
                    return totalProjectCostResolver({ estimate });
                },
            },
        ],
    },
    {
        id: "outOfScope",
        resolvers: [
            {
                id: "oosTotal",
                resolver: oosTotalResolver,
            },
        ],
    },
    {
        id: "overhead",
        resolvers: [
            {
                id: "oAndPTotal",
                resolver: oAndPTotalResolver,
            },
            {
                id: "oosOAndPTotal",
                resolver({ estimate }) {
                    let oosTotal = oosTotalResolver({ estimate });
                    let oAndPTotal = oAndPTotalResolver({ estimate });
                    return oosTotal + oAndPTotal;
                },
            },
        ],
    },
];

function oosTotalResolver({ estimate }) {
    return Object.keys(estimate.outOfScope).reduce((a, key) => {
        if (key !== "oosTotal") {
            return a + (estimate.outOfScope[key] || 0);
        }
        return a;
    }, 0);
}

function oAndPTotalResolver({ estimate }) {
    return Object.keys(estimate.overhead).reduce((a, key) => {
        if (key !== "oAndPTotal" && key !== "oosOAndPTotal") {
            return a + (estimate.overhead[key] || 0);
        }
        return a;
    }, 0);
}

function roofRCVResolver({ estimate }) {
    return (
        estimate.insuranceWork.totalInsuranceRCV -
        oosTotalResolver({ estimate })
    );
}

function totalProjectCostResolver({ estimate }) {
    return (
        roofRCVResolver({ estimate }) +
        estimate.insuranceWork.supplements +
        oAndPTotalResolver({ estimate }) +
        estimate.insuranceWork.warrantySold +
        estimate.insuranceWork.addlOutOfPocketExpenses
    );
}

function oAndPResolver({ estimate }) {
    return (
        estimate.insuranceWork.deductible -
        totalProjectCostResolver({ estimate })
    );
}

function profitResolver({
    estimate,
    materialsEstimateTotal,
    laborEstimateTotal,
}) {
    return (
        totalProjectCostResolver({ estimate }) -
        materialsEstimateTotal -
        laborEstimateTotal
    );
}

function marginBeforeCommissionResolver({
    estimate,
    materialsEstimateTotal,
    laborEstimateTotal,
}) {
    let profit = profitResolver({
        estimate,
        materialsEstimateTotal,
        laborEstimateTotal,
    });
    let totalProjectCost = totalProjectCostResolver({ estimate });
    let marginBeforeCommission = profit / totalProjectCost;
    if (marginBeforeCommission > 0.00000001) {
        return marginBeforeCommission || 0;
    } else {
        return 0;
    }
}

function commissionResolver() {
    return 0;
}

function marginAfterCommissionResolver({
    estimate,
    materialsEstimateTotal,
    laborEstimateTotal,
}) {
    let profit = profitResolver({
        estimate,
        materialsEstimateTotal,
        laborEstimateTotal,
    });
    let commission = commissionResolver();
    let totalProjectCost = totalProjectCostResolver({ estimate });
    let marginAfterCommission = (profit - commission) / totalProjectCost;
    if (marginAfterCommission > 0.00000001) {
        return marginAfterCommission || 0;
    } else {
        return 0;
    }
}

export { worksheets, worksheetResolvers };
