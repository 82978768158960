<template>
    <div class="w-full p-8 md:p-16">

        <Modal v-if="aiOpen" @hide="aiOpen = false" maxWidth="max-w-2xl">
            <div class="flex flex-col space-y-4 !m-0 !p-0">

                <h2 class="text-2xl">AI Analysis</h2>

                <div class="space-y-2">
                    <div v-for="response, i in aiResponses" :key="i" class="p-4 rounded-xl bg-gray-50 space-y-4" v-html="response" />
                </div>

                <textarea class="border-2 rounded p-4" name="" id="" cols="30" rows="10" v-model="aiQuery" placeholder="Ask AI a question about the data (ie: Which entity types are performing better?)"></textarea>

                <div>
                    <button class="px-3 py-2 rounded bg-gradient-to-r from-red-500 to-purple-500 text-white" @click="queryAi">Submit</button>
                </div>

            </div>


            <div v-if="aiRunning" class="fixed inset-0 dark:bg-black/50 bg-white/70 flex items-center justify-center w-full h-screen backdrop-blur">
                <div class="text-lg text-2xl font-semibold">Thinking...</div>
            </div>
        </Modal>

        <div class="w-full flex flex-col items-center">
            <h3 class="text-center text-lg opacity-30 font-semibold mb-1">{{ branch?.title }}</h3>
            <h2 class="text-center text-2xl mb-8">Team Stats</h2>

            <div class="hidden md:flex flex-col items-center w-full mb-16">
                <ReportChart type="branch" />
                <div class="w-full flex flex-col items-center">
                    <h2 class="text-center text-2xl mb-8">Top {{ admin ? '15 ' : ''}}By Category</h2>
                    <div class="text-xs flex space-x-2 items-center mb-8">
                        <input type="date" v-model="rangeStart" @blur="getReports" class="border border-gray-300 rounded px-2 py-1" />
                        <span class="mx-2">to</span>
                        <input type="date" v-model="rangeEnd" @blur="getReports" class="border border-gray-300 rounded px-2 py-1" />
                    </div>
                    <PieChart :reports="companyReports" type="rep" />
                </div>
            </div>

            <div class="flex flex-col items-center w-full">
                <div class="w-full mb-8" :class="view == 'spreadsheet' ? 'xl:grid grid-cols-4' : 'flex justify-between space-x-8'">

                    <div class="xl:flex flex-col space-y-4 hidden col-span-1 text-xs 2xl:text-sm 3xl:text-base">
                        <div class="space-x-2 flex items-center"><div class="2xl:w-6 2xl:h-6 w-4 h-4 border bg-red-50 border-gray-300" /><div> = Below Company Average</div></div>
                        <div class="space-x-2 flex items-center"><div class="2xl:w-6 2xl:h-6 w-4 h-4 border bg-green-50 border-gray-300" /><div> = Above Company Average</div></div>
                    </div>

                    <div class="flex space-x-4 items-center col-span-3 xl:col-span-2 flex-grow justify-center">

                        <input v-if="companyReports?.[0]?.repReports?.length >= 15" v-model="search" class="border rounded px-3 py-2 bg-white outline-none max-w-xl w-full" placeholder="Search" />
                        <div class="hidden xl:flex space-x-4 items-center">
                            <div>
                                <button v-tooltip="`Icons`" @click="view = 'icons'" v-if="view == 'spreadsheet'" class="px-2 py-1 border rounded text-2xl"><i class="fas fa-th-large" /></button>
                                <button v-tooltip="`Spreadsheet`" @click="view = 'spreadsheet'" v-else class="px-2 py-1 border rounded text-2xl"><i class="fal fa-th" /></button>
                            </div>
                            <div v-if="view == 'spreadsheet'">
                                <!-- <div class="uppercase text-xs tracking-widest opacity-50 mb-2">Date Range</div> -->
                                <div class="text-xs flex space-x-2 items-center">
                                    <input type="date" v-model="rangeStart" @blur="getReports" class="border border-gray-300 rounded px-2 py-1" />
                                    <span class="mx-2">to</span>
                                    <input type="date" v-model="rangeEnd" @blur="getReports" class="border border-gray-300 rounded px-2 py-1" />
                                </div>
                            </div>
                            <button v-tooltip="viewTotal ? 'Daily Average' : 'Total'" @click="viewTotal = !viewTotal" v-if="view == 'spreadsheet'" class="px-2 py-1 border rounded text-2xl"><i class="fad" :class="viewTotal ? 'fa-calendar' : 'fa-calendar-day'" /></button>
                            <button v-tooltip="`Export`" @click="exportCsv" v-if="view == 'spreadsheet'" class="px-2 py-1 border rounded text-2xl"><i class="fad fa-download" /></button>
                            <button v-if="view == 'spreadsheet'" @click="aiOpen = true" class="px-3 py-2 bg-gradient-to-r from-red-500 to-purple-500 text-white rounded">Ask&nbsp;AI</button>
                        </div>

                    </div>

                    <div class="xl:flex flex-col space-y-4 hidden opacity-0 col-span-1 text-xs 2xl:text-sm 3xl:text-base">
                        <div class="space-x-2 flex items-center"><div class="2xl:w-6 2xl:h-6 w-4 h-4 border bg-red-50 border-gray-300" /><div> = Below Company Average</div></div>
                        <div class="space-x-2 flex items-center"><div class="2xl:w-6 2xl:h-6 w-4 h-4 border bg-green-50 border-gray-300" /><div> = Above Company Average</div></div>
                    </div>

                </div>
                <Spinner v-if="loadingReports" :small="true" class="w-16 h-16 mx-auto mt-16" />
                <div v-else-if="view == 'icons'" class="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-8 lg:gap-16">
                    <ReportInfo
                        v-for="rep in filteredReps"
                        :key="rep.id"
                        :rep="rep"
                        :companyReport="companyReports?.[0]"
                    />
                </div>
                <div v-else class="w-full text-xs md:text-sm 3xl:text-base relative" :class="{'pointer-events-none': loadingReports}">
                    <div v-if="loadingReports" class="absolute z-50 inset-0 bg-black/50 backdrop-blur-[2px] flex items-start justify-center">
                        <div class="text-6xl 2xl:text-8xl font-black uppercase mt-16 2xl:mt-32 text-white">Loading</div>
                    </div>
                    <table class="w-full border-collapse border border-slate-500">
                        <thead>
                            <tr>
                                <th @click="sort('title')" :class="{'bg-gray-400': sortField == 'title'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600">Branch<i v-if="sortField == 'title'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('role')" :class="{'bg-gray-400': sortField == 'role'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600">Role<i v-if="sortField == 'role'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('conversion')" :class="{'bg-gray-400': sortField == 'conversion'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Conversion</span><span class="3xl:hidden">C/K%</span><i v-if="sortField == 'conversion'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('knock')" :class="{'bg-gray-400': sortField == 'knock'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Knock</span><span class="3xl:hidden">K</span><i v-if="sortField == 'knock'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('talk')" :class="{'bg-gray-400': sortField == 'talk'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Talk</span><span class="3xl:hidden">T</span><i v-if="sortField == 'talk'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('walk')" :class="{'bg-gray-400': sortField == 'walk'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Walk</span><span class="3xl:hidden">W</span><i v-if="sortField == 'walk'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('contingency')" :class="{'bg-gray-400': sortField == 'contingency'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Contingency</span><span class="3xl:hidden">C</span><i v-if="sortField == 'contingency'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('approvals')" :class="{'bg-gray-400': sortField == 'approvals'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Approvals</span><span class="3xl:hidden">A</span><i v-if="sortField == 'approvals'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('contracts')" :class="{'bg-gray-400': sortField == 'contracts'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Contracts</span><span class="3xl:hidden">C</span><i v-if="sortField == 'contracts'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('revenue')" :class="{'bg-gray-400': sortField == 'revenue'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Revenue</span><span class="3xl:hidden">Revenue</span><i v-if="sortField == 'revenue'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('softSets')" :class="{'bg-gray-400': sortField == 'softSets'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Soft Sets</span><span class="3xl:hidden">SS</span><i v-if="sortField == 'softSets'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                                <th @click="sort('salesOpportunities')" :class="{'bg-gray-400': sortField == 'salesOpportunities'}" class="cursor-pointer p-2 bg-gray-200 text-left border border-slate-600"><span class="hidden 3xl:inline">Sales Opportunities</span><span class="3xl:hidden">SO</span><i v-if="sortField == 'salesOpportunities'" :class="sortDirection == 'asc' ? `fa-chevron-down mt-[4px]` : `fa-chevron-up mt-[2px]`" class="fas opacity-50 float-right" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="companyAverages?.knock">
                                <td class="p-2 border bg-blue-50 border-slate-700 font-semibold">Company {{ admin && viewTotal ? 'Totals' : 'Averages'}}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700"></td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((companyAverages?.contingency/companyAverages?.knock)*100)?.toFixed(2) }}%</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.knock)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.talk)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.walk)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.contingency)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.approvals)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.contracts)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ Math.round((admin && viewTotal ? companyTotals : companyAverages)?.revenue).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.softSets)?.toFixed(0) }}</td>
                                <td class="p-2 border bg-blue-50 border-slate-700">{{ ((admin && viewTotal ? companyTotals : companyAverages)?.salesOpportunities)?.toFixed(0) }}</td>
                            </tr>
                            <ReportTableRow
                                v-for="rep in filteredReps"
                                :key="rep.id"
                                :rep="rep"
                                :companyReports="companyReports"
                                :companyTotals="companyTotals"
                                :companyAverages="companyAverages"
                                :viewTotal="viewTotal"
                                :ref="setRowRef"
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import People from '../components/layout/people.vue'
import createVcard from '../helpers/createVcard';
import roles from '../../../config/helpers/roles'
import Mango from '../helpers/mango';
import Swal from 'sweetalert2';
import latestCutoff from '../helpers/latestCutoff';
import ReportInfo from '../components/layout/report-info.vue';
import ReportTableRow from '../components/layout/report-table-row.vue';
import dayjs from 'dayjs'
import downloadCsv from '../helpers/download-csv';
import ReportChart from '../components/layout/reportChart.vue';
import PieChart from '../components/layout/pieChart.vue';

let numberFields = [
    'knock',
    'talk',
    'walk',
    'contingency',
    'approvals',
    'contracts',
    'revenue',
    'softSets',
    'salesOpportunities',
]

let afterSevenThirtyFive = dayjs().hour() >= 19 && dayjs().minute() >= 35

export default {
	components: { People, ReportInfo, ReportTableRow, ReportChart, PieChart },
    inject: ['store','axios'],
    data() {
        return {
            branch: {},
            reps: [],
            inviteRole: null,
            downloadingContacts: false,
            search: null,
            view: 'icons',
            viewTotal: false,
            aiOpen: false,
            aiQuery: null,
            aiRunning: false,
            aiStatus: null,
            aiResponses: [],
            sortDirection: 'desc',
            sortField: null,
            loadingReports: false,
            loadingBranchReports: false,
            rangeStart: afterSevenThirtyFive ? dayjs().format('YYYY-MM-DD') : dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            rangeEnd: afterSevenThirtyFive ? dayjs().format('YYYY-MM-DD') : dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            roles,
            companyReports: [],
            branchReports: [],
            repReports: [],
            rowRefs: [],
        }
    },
    async mounted() {
        this.branch = await Mango.branch(this.$route.params?.branchId)
        this.getReports()
    },
    methods: {
        async queryAi() {

            let csvContent = "data:text/csv;charset=utf-8,"
                + "Branch,Entity Type,Conversion,Knock,Talk,Walk,Contingency,Approvals,Contracts,Revenue,Soft Sets,Sales Opportunities\n"
                + this.rowRefs?.map(r => {
                    return `
                        "${r.rep.title?.replace(/"/g, '""')}",
                        ${r.rep?.roles?.join(',')?.replaceAll('sales', 'sales ')?.toTitleCase() || ''},
                        ${r.conversion},
                        ${r.display?.knock},
                        ${r.display?.talk},
                        ${r.display?.walk},
                        ${r.display?.contingency},
                        ${r.display?.approvals},
                        ${r.display?.contracts},
                        "${r.revenue?.replace(/"/g, '""')}",
                        ${r.display?.softSets},
                        ${r.display?.salesOpportunities}
                    `.replace(/\n/g, '').replace(/\s+/g, ' ')
                }).join('\n')

            // let schema = Mango.collections.find(c => c.name == 'churches').fields.map(f => f.name)

            let prompt = `
                Here is a CSV listing each of Best Choice Roofing's branches along with their ${this.viewTotal ? 'total' : 'daily average'} numbers for ${this.rangeStart} through ${this.rangeEnd}.

                ${csvContent}

                ${this.aiQuery}

                Please respond in valid HTML in JSON like this:

                {
                    "html": "<p>this is my response</p>"
                }
            `

            this.aiRunning = true

            let response = (await this.axios.post(`${this.store.api}/controllers/ai/prompt`, {prompt, json: true}))?.data
            // let response = (await this.axios.post(`https://api.churchandfamilylife.com/controllers/ai/prompt`, {prompt, json: true}))?.data
            if (response?.html) {
                this.aiResponses.push(response.html)
                this.aiQuery = ''
            }

            this.aiRunning = false

        },
        exportCsv() {
            let csvContent = "data:text/csv;charset=utf-8,"
                + "Branch,Entity Type,Conversion,Knock,Talk,Walk,Contingency,Approvals,Contracts,Revenue,Soft Sets,Sales Opportunities\n"
                + this.rowRefs?.map(r => {
                    return `
                        "${r.rep.title?.replace(/"/g, '""')}",
                        "${r.rep?.roles?.join(',')?.replaceAll('sales', 'sales ')?.toTitleCase() || ''}",
                        ${r.conversion},
                        ${r.display?.knock},
                        ${r.display?.talk},
                        ${r.display?.walk},
                        ${r.display?.contingency},
                        ${r.display?.approvals},
                        ${r.display?.contracts},
                        "${r.revenue?.replace(/"/g, '""')}",
                        ${r.display?.softSets},
                        ${r.display?.salesOpportunities}
                    `.replace(/\n/g, '').replace(/\s+/g, ' ').replaceAll('", "', '","')
                }).join('\n')
            downloadCsv(csvContent, `${this.branch.title.split(',')[0]} Team.csv`)
        },
        setRowRef(el) {
            let elIndex = this.rowRefs?.findIndex?.(ref => ref?.rep?.id === el?.rep?.id)
            if (elIndex >= 0) return this.rowRefs[elIndex] = el
            if (el) return this.rowRefs.push(el);
        },
        async getReports() {
            this.loadingReports = true

            let start = latestCutoff(dayjs(this.rangeStart).add(1, 'day'))
            let end = latestCutoff(dayjs(this.rangeEnd).add(2, 'days'))

            console.log('range', dayjs(start).format('MM-DD hh:mm'), dayjs(end).format('MM-DD hh:mm'))

            let gql = `
            query {
                companyReports(
                    search: {compareCreated: {greaterThan: ${latestCutoff(start)*1}, lessThan: ${latestCutoff(end)*1}}},
                    limit: 12,
                    sort: {created: -1}
                ) {
                    activeReps
                    repReports(search: {branch: "${this.$route.params?.branchId}"}, limit: 600) {
                        rep {
                            id
                            title
                            roles
                            status
                            image {url}
                            created
                            emoji
                        }
                        ${numberFields.map(f => `${f}`).join('\n')}
                    }
                    ${numberFields.map(f => `${f}`).join('\n')}
                }
            }`

            this.companyReports = (await Mango.graphql(gql))?.companyReports

            // this.companyReports = await Mango.companyReports({
            //     search: {
            //         compareCreated: {
            //             greaterThan: latestCutoff(this.rangeStart),
            //             lessThan: latestCutoff(this.rangeEnd)
            //         }
            //     },
            //     limit: 12,
            //     sort: {created: -1},
            //     fields: [...numberFields, 'repReports'],
            // })

            // this.repReports = await Mango.repReports({
            //     search: {
            //         branch: this.$route.params?.branchId,
            //         compareCreated: {
            //             greaterThan: latestCutoff(this.rangeStart),
            //             // lessThan: latestCutoff(this.rangeEnd)
            //         }
            //     },
            //     sort: {created: -1},
            //     fields: [...numberFields, 'rep', 'shotgun'],
            //     limit: 600
            // })

            let cr = this.companyReports[0]
            console.log('cr.repReports', cr.repReports, cr)
            let repIds = Array.from(new Set(cr.repReports.map(r => r.rep?.id)))
            this.reps = repIds.map(id => cr.repReports.find(r => r.rep?.id == id)?.rep)
            this.reps.forEach(r => r.title = r.title?.toTitleCase())
            // this.companyReports[0].repReports = this.repReports

            if (this.sortField) {
                this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc'
                this.sort(this.sortField)
            }

            this.loadingReports = false
        },
        sort(field) {

            let companyReports = this.companyReports
            let repReports = companyReports?.flatMap(cr => cr?.repReports)

            if (this.sortField == field) {
                this.sortDirection = this.sortDirection == 'asc' ? 'desc' : 'asc'
            } else {
                this.sortDirection = 'desc'
            }

            this.sortField = field

            this.reps = this.reps.sort((a,b) => {

                let aReports = repReports?.filter(r => r.rep?.id == a.id)
                let bReports = repReports?.filter(r => r.rep?.id == b.id)
                let aVal, bVal

                if (field == 'title') {
                    aVal = a.title
                    bVal = b.title
                } else if (field == 'conversion') {
                    let aContingencies = aReports?.reduce((a,r) => a+(r?.contingency || 0), 0)
                    let bContingencies = bReports?.reduce((a,r) => a+(r?.contingency || 0), 0)
                    let aKnocks = aReports?.reduce((a,r) => a+(r?.knock || 0), 0)
                    let bKnocks = bReports?.reduce((a,r) => a+(r?.knock || 0), 0)
                    aVal = (aContingencies / aKnocks) || 0
                    bVal = (bContingencies / bKnocks) || 0
                } else if (field == 'role') {
                    aVal = aReports?.reduce((a,r) => a+(r?.rep?.roles?.[0] || 0), 0)
                    bVal = bReports?.reduce((a,r) => a+(r?.rep?.roles?.[0] || 0), 0)
                } else {
                    aVal = aReports?.reduce((a,r) => a+(r?.[field] || 0), 0)
                    bVal = bReports?.reduce((a,r) => a+(r?.[field] || 0), 0)
                }

                // console.log(aVal, bVal, this.sortDirection)

                if (this.sortDirection == 'asc') {
                    return aVal > bVal ? 1 : -1
                } else {
                    return aVal < bVal ? 1 : -1
                }

            })

        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        filteredReps() {
            if (!this.search) return this.reps
            return this.reps.filter(b => b.title.toLowerCase().includes(this.search.toLowerCase()))
        },
        companyTotals() {
            if (!this.companyReports?.length) return
            let companyReports = this.companyReports
            return {

                knock: companyReports.map(r => r.knock).reduce((a, b) => a + b, 0),
                talk: companyReports.map(r => r.talk).reduce((a, b) => a + b, 0),
                walk: companyReports.map(r => r.walk).reduce((a, b) => a + b, 0),
                contingency: companyReports.map(r => r.contingency).reduce((a, b) => a + b, 0),

                approvals: companyReports.map(r => r.approvals).reduce((a, b) => a + b, 0),
                contracts: companyReports.map(r => r.contracts).reduce((a, b) => a + b, 0),
                revenue: companyReports.map(r => r.revenue).reduce((a, b) => a + b, 0),
                softSets: companyReports.map(r => r.softSets).reduce((a, b) => a + b, 0),
                salesOpportunities: companyReports.map(r => r.salesOpportunities).reduce((a, b) => a + b, 0),

            }
        },
        companyAverages() {
            if (!this.companyReports?.length) return
            let companyReports = this.companyReports
            let totalActiveReps = companyReports.reduce((a, cr) => cr.activeReps + a, 0)
            return Object.keys(this.companyTotals).reduce((a,k) => ({...a, [k]: (this.companyTotals[k]||0)/totalActiveReps}),{})
        }
    }
}
</script>

<style>

</style>
