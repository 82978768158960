<template>
    <div>
        <label :uploading="uploading" :error="error" :url="url">
            <slot />
            <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                @change="upload"
            />
        </label>
    </div>
</template>

<script>
import { apiDomain } from '../../../config/config/settings.json'

export default {
    data() {
        return {
            uploading: false,
            error: false,
            url: null
        }
    },
    watch: {
        uploading() { this.$emit('uploading', this.uploading) },
        error() { this.$emit('error', this.error) },
        url() { this.$emit('url', this.url) },
    },
    methods: {
        upload(event) {
            this.uploading = true
            const file = event.target.files[0]
            const formData = new FormData()

            formData.append('file', file)

            const request = fetch(`${apiDomain}/upload`, {
                method: 'POST',
                body: formData,
            })
            .then((response) => response.json())
            .then((json) => {
                const path = json.paths[0]
                const url = apiDomain + path
                this.uploading = true
                this.url = url
            })
            .catch(e => this.error = e)
        },
    }
}
</script>

<style>

</style>
