<template>
    <div class="border dark:border-0 bg-white dark:bg-gray-800 rounded-lg lg:col-span-3 overflow-hiden fix-rounding-safari h-full shadow-card max-h-[750px]">

        <Modal v-if="selectedPhoto" @hide="selectedPhoto = null" maxWidth="max-w-4xl !p-0">
            <img :src="selectedPhoto" class="w-full" />
        </Modal>

        <Modal v-if="branchModalOpen" @hide="branchModalOpen = null" :allowOverflow="true">
            <div class="rounded-lg p-4 overflow-visible">
                <div class="text-xs uppercase tracking-widest font-semibold opacity-50 mb-2">Filter Branches</div>
                <MultiSelect
                    v-model:modelSelectedIds="branchIds"
                    v-model:modelSelectedEntries="branchEntries"
                    :algoliaFilters="branchSearchFilters"
                    collection="branches"
                    :multiple="true"
                    :create="false"
                />
                <div class="flex justify-between items-center py-8">
                    <div class="flex-grow h-1 bg-gray-50" />
                    <div class="px-4">OR</div>
                    <div class="flex-grow h-1 bg-gray-50" />
                </div>
                <div class="flex gap-4 flex-wrap items-center mb-8 justify-center">
                    <button
                        v-for="option in ['corporate','franchise','partner']"
                        :key="option"
                        class="px-3 py-2 rounded border"
                        :class="entityType == option ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''"
                        @click="entityType = entityType == option ? null : option; branchModalOpen = false"
                        v-html="option?.toTitleCase()"
                    />
                </div>
            </div>
        </Modal>

        <div class="flex flex-col lg:flex-row relative h-full">
            <div  class="left-0 w-full lg:w-2/3 h-full min-h-[368px]">
                <!-- :dark="darkMode.enabled" -->
                <Mapbox
                    :markers="leadMarkers"
                    :hover="true"
                    v-model:hoveredMarker="hoveredOpportunityId"
                />
            </div>
            <div class="w-full lg:w-1/3 h-full right-0 top-0 overflow-hidden flex flex-col">


                <div v-if="leadMarkers.length" class="flex flex-col justify-center items-center border-b-4 shadow-card">

                    <button @click="branchModalOpen = true" v-if="admin || branches?.length > 1" class="absolute m-4 top-0 right-0">
                        <i class="fal fa-cog mr-2" />
                    </button>

                    <div class="text-xs 2xl:text-sm text-gray-500 mb-4 flex flex-col justify-center items-center w-full p-4 md:p-8 !pb-0">
                        <div class="uppercase font-semibold">
                            <!-- <span v-if="branch">{{ branch.title }}</span> -->
                            <span>Today's Opportunities</span>
                        </div>
                        <div v-if="entityType">{{ entityType.toTitleCase() }}</div>
                        <div v-else-if="branchEntries.length">
                            <div class="italic text-2xs 2xl:text-xs" v-html="branchEntries.map(b => b.title).join(', ')" />
                        </div>
                        <!-- <div>
                            <div v-if="opportunities?.length" class="px-2 py-1 rounded bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-xs" >
                                Total:&nbsp;{{ opportunities.length?.toLocaleString() }}
                            </div>
                        </div> -->
                    </div>

                    <div v-if="opportunities.length" class="flex space-x-4 lg:space-x-2 2xl:space-x-4 font-mono mx-auto mb-4">
                        <div class="bg-red-50 w-14 h-14 lg:h-12 lg:w-12 2xl:w-16 2xl:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50">Knock</div>
                            <div class="">{{ totals.knock || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 lg:h-12 lg:w-12 2xl:w-16 2xl:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50">talk</div>
                            <div class="">{{ totals.talk || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 lg:h-12 lg:w-12 2xl:w-16 2xl:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50">walk</div>
                            <div class="">{{ totals.walk || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 lg:h-12 lg:w-12 2xl:w-16 2xl:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs lg:text-2xs 2xl:text-xs tracking-widest uppercase leading-tight opacity-50">ctgy</div>
                            <div class="">{{ totals.contingency || 0 }}</div>
                        </div>
                    </div>

                    <div class="font-mono text-xl mb-4 rounded px-3 py-2 border-2 bg-gray-50 font-semibold">
                        <span class="text-red-500">{{ totals.contingency }}</span><span>-</span>
                        <span class="text-orange-500">{{ totals.approvals }}</span><span>-</span>
                        <span class="text-yellow-500">{{ totals.contracts }}</span><span>-</span>
                        <span class="text-green-500">{{ totals.revenue }}</span><span>-</span>
                        <span class="text-blue-500">{{ totals.softSets }}</span><span>-</span>
                        <span class="text-purple-500">{{ totals.salesOpportunities }}</span>
                    </div>

                </div>
                <div v-else class="w-full h-full flex flex-col justify-center items-center p-8 text-center">
                    <div class="text-2xl font-bold opacity-20">No Opportunities Today</div>
                    <div class="opacity-40 text-sm mt-2">Once sales reps gather some opportunities they will appear here.</div>
                </div>

                <div v-if="loading || loadingBranches" class="p-8 flex justify-center"><Spinner :small="true" class="w-8 h-8" /></div>
                <div v-else-if="leadMarkers.length" class="divide-y dark:divide-gray-700 overflow-scroll p-4 md:p-8">
                    <a v-for="opportunity in filteredOpportunities.filter((o,i) => i < 500)" :key="opportunity.id" @mouseover="hoveredOpportunityId = opportunity.id" @mouseout="hoveredOpportunityId = null" target="_blank" :href="`/opportunities/${opportunity.id}`" class="flex items-center space-x-4 p-2 group">
                        <div
                            class="w-12 h-12 shrink-0 mr-4 bg-center bg-cover rounded border-white dark:border-gray-400 bg-gray-400 dark:bg-gray-700 uppercase text-white dark:text-gray-400 flex items-center justify-center"
                            v-lazy:background-image="opportunity.selfie?.url"
                            @click.stop.prevent="selectedPhoto = opportunity?.selfie?.url"
                        >
                            <div v-if="!opportunity?.selfie?.url"><i class="fa fa-house" /></div>
                        </div>
                        <div class="flex-grow truncate">
                            <div class="text-sm truncate group-hover:underline">{{ opportunity.title }}</div>
                            <div class="text-xs text-gray-500 truncate">
                                <component :is="opportunity.ownerInfo?.email ? 'a' : 'span'" :class="{'hover:underline': opportunity.ownerInfo?.email}" :href="`mailto:${opportunity.ownerInfo?.email}`">
                                    <span class="opacity-60">{{ opportunity.status }}</span>
                                    <span v-if="opportunity.ownerInfo?.firstName"> | {{ opportunity.ownerInfo?.firstName }} {{ opportunity.ownerInfo?.lastName }}</span>
                                </component>
                            </div>
                        </div>
                        <div class="text-xs text-green-500 dark:text-green-700 font-semibold whitespace-nowrap">{{ dayjs(opportunity.created).format('MMM. D') }}</div>
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import Mapbox from '../components/network/mapbox.vue'
import Modal from '../components/layout/modal.vue'
import MultiSelect from '../helpers/multiSelect.vue'
import Mango from '../helpers/mango'

export default {
    props: ['opportunities', 'startRange'],
    inject: ['darkMode','store'],
    components: {Mapbox, Modal, MultiSelect},
    data() {
        return {
            loading: false,
            selectedPhoto: null,
            hoveredOpportunityId: null,
            loadingBranches: false,
            branchModalOpen: false,
            oneMonthAgo: new Date(new Date().setMonth(new Date().getMonth() - 1)).getTime(),
            oneWeekAgo: new Date(new Date().setDate(new Date().getDate() - 7)).getTime(),
            view: 'week',
            branchIds: [],
            branchEntries: [],
            entityType: null,
            branchMap: null
        }
    },
    methods: {
        dayjs,
        inRange(l, param) {
            let endRange = new Date()
            param = l?.timestamps?.[param] || l?.[param]
            if (param) param = new Date(param)
            let inRange = param < endRange && param > this.startRange
            return inRange
        }
    },
    mounted() {
    },
    watch: {
        async entityType() {
            if (!this.branchMap) {
                this.loadingBranches = true
                this.branchMap = await Mango.branches({ fields: ['entityType'], limit: 999 })
                this.loadingBranches = false
            }
            if (this.entityType) {
                this.branchEntries = []
                this.branchIds = []
            }
        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        branches() {
            if (!this.admin) {
                return this.store?.user?.branches || []
            }
        },
        branchSearchFilters() {
            if (this.branches) {
                return this.branches.map(branch => `objectID:${branch.id}`).join(' OR ');
            }
        },
        entities() {
            return this.branchMap?.reduce((acc, b) => {
                if (!acc[b.entityType]) acc[b.entityType] = [b.id]
                else acc[b.entityType].push(b.id)
                return acc
            }, {})
        },
        filteredOpportunities() {
            if (!this.branchIds.length && !this.entityType) return this.opportunities
            if (this.entityType) return this.opportunities?.filter(o => this.entities?.[this.entityType].includes(o.branch?.id))
            return this.opportunities.filter(o => this.branchIds.includes(o.branch?.id || o.branch))
        },
        totals() {

            // Calculate the revenue
            let revenueOpportunities = this.filteredOpportunities.filter(l => this.inRange(l, 'revenue'))
            let revenueAlreadyReported = revenueOpportunities.reduce((a, l) => a + (l.revenueReported || 0), 0)
            let totalRevenue = revenueOpportunities.reduce((a, l) => a + (l.revenue || 0), 0)
            let netRevenue = totalRevenue - revenueAlreadyReported

            console.log('netRevenue', netRevenue, revenueAlreadyReported)

            return {
                knock: this.filteredOpportunities.filter(l => this.inRange(l, 'knock')).filter(l => !!l.ktwc?.knock).length,
                talk: this.filteredOpportunities.filter(l => this.inRange(l, 'talk')).filter(l => !!l.ktwc?.talk).length,
                walk: this.filteredOpportunities.filter(l => this.inRange(l, 'walk')).filter(l => !!l.ktwc?.walk).length,
                contingency: this.filteredOpportunities.filter(l => this.inRange(l, 'contingency')).filter(l => !!l.ktwc?.contingency).length,

                approvals: this.filteredOpportunities.filter(l => this.inRange(l, 'insuranceAccepted')).filter(l => !!l.insuranceAccepted).length,
                contracts: this.filteredOpportunities.filter(l => this.inRange(l, 'contractSigned')).filter(l => !!l.contractSigned).length,
                revenue: netRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                softSets: this.filteredOpportunities.filter(l => this.inRange(l, 'softSet')).filter(l => l?.inspection?.toLowerCase?.() === 'soft set' || l?.discussion?.toLowerCase?.() === 'soft set').length,
                salesOpportunities: this.filteredOpportunities.filter(l => this.inRange(l, 'created')).length,

                estimate: this.filteredOpportunities.filter(l => this.inRange(l, 'estimate')).reduce((a, l) => a + (l.estimate || 0), 0),
            }
        },
        revenue() {
            let revenue = this.filteredOpportunities?.reduce((acc, opportunity) => {
                return acc + (opportunity.revenue || 0)
            }, 0)

            // Add a dollar sign to the beginning
            return `$${revenue.toLocaleString()}`;
        },
        leadMarkers() {
            return this.filteredOpportunities?.filter(m => m.address?.coordinates?.lat && m.address?.coordinates?.lng)?.map(m => {
                return {
                    id: m.id,
                    collection: 'opportunities',
                    coordinates: [m.address?.coordinates?.lng, m.address?.coordinates?.lat],
                    popup: `
                    <div class="hidden xs:block w-full max-w-xs text-left bg-white dark:bg-gray-800 divide-y dark:divide-gray-500 rounded-lg">

                        <div class="w-full p-4 flex items-center">
                            <div
                                class="w-12 h-12 shrink-0 mr-4 bg-center bg-cover rounded-full border-white dark:border-gray-400 bg-gray-400 dark:bg-gray-700 uppercase text-white dark:text-gray-400 flex items-center justify-center"
                                style="background-image: url(${m.selfie?.url})"
                            >
                                ${m.selfie?.url ? `` : `<div>${m?.title?.[0]}</div>`}
                            </div>
                            <div>
                                <div class="text-sm">${m.ownerInfo?.firstName ? `${m.ownerInfo.firstName} ${m.ownerInfo?.lastName}` : 'No Name Recorded'}</div>
                                <div class="italic text-gray-400 text-xs">${m.address?.city}, ${m.address?.state}</div>
                            </div>
                        </div>

                    </div>
                    `
                }
            })
        }
    }
}
</script>

<style>

</style>
