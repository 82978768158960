<template>
    <tr v-if="reports?.length">
        <td class="p-2 border border-slate-700 bg-white">
            <router-link
                :to="type == 'branch' ? `/${branchPath}/${branch?.id}` : `/members/${rep?.id}/report`"
                class="underline"
                :class="['closed','disabled'].includes(rep?.status) ? 'opacity-50' : 'opacity-100'"
            >{{ target.title }}</router-link>
        </td>
        <td class="p-2 border border-slate-700 truncate text-xs bg-white">{{ branch?.entityType?.toTitleCase?.() || rep?.roles?.join(', ')?.replaceAll('sales', 'sales ')?.toTitleCase() }}</td>
        <td :class="average?.conversion ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ conversion }}</td>
        <td :class="average?.knock ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.knock }}</td>
        <td :class="average?.talk ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.talk }}</td>
        <td :class="average?.walk ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.walk }}</td>
        <td :class="average?.contingency ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.contingency }}</td>
        <td :class="average?.approvals ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.approvals }}</td>
        <td :class="average?.contracts ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.contracts }}</td>
        <td :class="average?.revenue ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ revenue }}</td>
        <td :class="average?.softSets ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.softSets }}</td>
        <td :class="average?.salesOpportunities ? 'bg-green-50' : 'bg-red-50'" class="p-2 border border-slate-700">{{ display?.salesOpportunities }}</td>
    </tr>
</template>

<script>
export default {
    props: ['branch', 'rep', 'companyReports', 'companyTotals', 'companyAverages', 'viewTotal'],
    watch: {
        display() {
            this.$emit('numbers', this.display)
        }
    },
    computed: {
        type() {
            return this.branch ? 'branch' : 'rep'
        },
        target() {
            return this.branch || this.rep
        },
        reports() {
            return this.companyReports?.map(cr => cr?.[`${this.type}Reports`]?.find(report => report[this.type]?.id === this.target?.id))
        },
        conversion() {
            if (!this.branchTotals?.contingency) return '0%'
            let conversion = (((this.branchTotals?.contingency / this.branchTotals?.knock) || 0) * 100)
            conversion = conversion?.toFixed(2) + '%'
            return conversion.replace('.00', '').replace('y%', 'y')
        },
        revenue() {
            if (!this.reports?.length) return
            let reportsRevenue = this.reports.reduce((a,r) => a+(r?.revenue||0), 0)
            return Math.round(reportsRevenue).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
        },
        branchTotals() {
            if (!this.reports?.length) return
            return {

                knock: this.reports.map(r => (r?.knock||0)).reduce((a, b) => a + b, 0),
                talk: this.reports.map(r => (r?.talk||0)).reduce((a, b) => a + b, 0),
                walk: this.reports.map(r => (r?.walk||0)).reduce((a, b) => a + b, 0),
                contingency: this.reports.map(r => (r?.contingency||0)).reduce((a, b) => a + b, 0),

                approvals: this.reports.map(r => (r?.approvals||0)).reduce((a, b) => a + b, 0),
                contracts: this.reports.map(r => (r?.contracts||0)).reduce((a, b) => a + b, 0),
                revenue: this.reports.map(r => (r?.revenue||0)).reduce((a, b) => a + b, 0),
                softSets: this.reports.map(r => (r?.softSets||0)).reduce((a, b) => a + b, 0),
                salesOpportunities: this.reports.map(r => (r?.salesOpportunities||0)).reduce((a, b) => a + b, 0),

            }
        },
        average() {
            return {

                knock: this.branchTotals?.knock >= this.companyAverages?.knock,
                talk: this.branchTotals?.talk >= this.companyAverages?.talk,
                walk: this.branchTotals?.walk >= this.companyAverages?.walk,
                contingency: this.branchTotals?.contingency >= this.companyAverages?.contingency,

                approvals: this.branchTotals?.approvals >= this.companyAverages?.approvals,
                contracts: this.branchTotals?.contracts >= this.companyAverages?.contracts,
                revenue: this.branchTotals?.revenue >= this.companyAverages?.revenue,
                softSets: this.branchTotals?.softSets >= this.companyAverages?.softSets,
                salesOpportunities: this.branchTotals?.salesOpportunities >= this.companyAverages?.salesOpportunities,

                conversion: (this.branchTotals?.contingency/this.branchTotals?.knock) >= (this.companyAverages?.contingency/this.companyAverages?.knock)

            }
        },
        display() {

            return {
                knock: this.viewTotal ? this.branchTotals?.knock : (this.branchTotals?.knock/this.reports?.length)?.toFixed?.(0),
                talk: this.viewTotal ? this.branchTotals?.talk : (this.branchTotals?.talk/this.reports?.length)?.toFixed?.(0),
                walk: this.viewTotal ? this.branchTotals?.walk : (this.branchTotals?.walk/this.reports?.length)?.toFixed?.(0),
                contingency: this.viewTotal ? this.branchTotals?.contingency : (this.branchTotals?.contingency/this.reports?.length)?.toFixed?.(0),

                approvals: this.viewTotal ? this.branchTotals?.approvals : (this.branchTotals?.approvals/this.reports?.length)?.toFixed?.(0),
                contracts: this.viewTotal ? this.branchTotals?.contracts : (this.branchTotals?.contracts/this.reports?.length)?.toFixed?.(0),
                revenue: this.viewTotal ? this.branchTotals?.revenue : (this.branchTotals?.revenue/this.reports?.length)?.toFixed?.(0),
                softSets: this.viewTotal ? this.branchTotals?.softSets : (this.branchTotals?.softSets/this.reports?.length)?.toFixed?.(0),
                salesOpportunities: this.viewTotal ? this.branchTotals?.salesOpportunities : (this.branchTotals?.salesOpportunities/this.reports?.length)?.toFixed?.(0),
            }

        },
        branchPath() {
            return this.$route.path.includes('stats') ? 'team-stats' : 'my-team'
        }
    }
}
</script>

<style>

</style>
