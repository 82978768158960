<template>
	<div ref="mapRef" class="w-full h-full" />
    <slot name="popups" />
</template>

<script setup>
import { mapboxAccessToken } from "../../../../config/config/settings.json"
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { onMounted, watch, ref, inject } from "vue"
import { useModel } from '../../helpers/model'
import { useRouter, useRoute } from 'vue-router'
import Supercluster from 'supercluster';

let breakpoints = inject('breakpoints')

const router = useRouter()
const route = useRoute()

const emit = defineEmits(['update:bounds', 'update:hoveredMarker'])

const props = defineProps({
	markers: { type: Array },
	center: { type: Array },
	bbox: { type: Array },
	dark: { type: Boolean },
	hover: { type: Boolean },
	geolocate: { type: Boolean },
    hoveredMarker: {type: String}
});

let mapRef = ref(null)
let hoveredMarker = useModel(props, emit, 'hoveredMarker')


mapboxgl.accessToken = mapboxAccessToken

let map = {}
let markers = []

function setBounds() {
    const bounds = map.getBounds();
    emit('update:bounds', bounds)
}

function loadMarkers() {
    // Clear existing markers
    markers.forEach(m => m.remove());
    markers = [];

    clusterIndex = new Supercluster({
        radius: 10,
        maxZoom: 16
    });

    clusterIndex.load(props.markers.map(marker => ({
        type: 'Feature',
        properties: { cluster: false, ...marker },
        geometry: { type: 'Point', coordinates: marker.coordinates }
    })));


    // Get clusters for the current map bounds and zoom level
    const clusters = clusterIndex.getClusters(map.getBounds().toArray().flat(), map.getZoom());

    clusters.forEach(cluster => {
        const { cluster: isCluster, point_count: pointCount } = cluster.properties;

        if (isCluster) {
            // This is a cluster, so we create a different type of marker
            const el = document.createElement('div');
            el.className = 'marker cluster-marker';
            el.innerText = pointCount;

            const mkr = new mapboxgl.Marker(el)
                .setLngLat(cluster.geometry.coordinates)
                .addTo(map);

            markers.push(mkr);
        } else {

            const marker = cluster.properties;
            // This is a single point, create your normal marker
            const el = document.createElement('div');
            el.className = 'marker text-2xs aspect-square flex items-center justify-center bg-blue-500 text-white rounded-full text-center p-2 font-semibold border border-white';

            let mkr = new mapboxgl.Marker(el)
            .setLngLat(marker.coordinates)
            .setPopup(
                new mapboxgl.Popup({
                    maxWidth: '20rem',
                    closeButton: false,
                    offset: 0,
                })
                .setHTML(marker.popup || '')
            )
            .addTo(map);

            mkr.id = marker.id
            mkr.isCluster = isCluster
            mkr.collection = marker.collection
            markers.push(mkr)

            // Open popup on hover
            if (props.hover) {
                el.addEventListener('mouseenter', () => hoveredMarker.value = mkr.id);
                el.addEventListener('mouseleave', () => hoveredMarker.value = null);

                el.addEventListener('click', () => {if (breakpoints.xs) {
                    let path = `/${mkr.collection}/${mkr.id}`
                    window.open(path, "_blank") || window.location.replace(path);
                }});
            }
        }
    });
}


watch(() => props.dark, () => {
    map.setStyle(props.dark ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10')
})

// watch(() => props.center, () => map.flyTo({center: props.center, zoom: 7}))
watch(() => props.bbox, () => map.fitBounds(props.bbox, {duration:0}))

watch(() => props.hoveredMarker, (n,o) => {

    markers.filter(m => m?.isCluster === false).forEach(m => {
        let el = m.getElement()
        el.className = el.className.replace('bg-green-500', 'bg-blue-500')
        m.getPopup().remove()
    })

    if (n) {
        let marker = markers.find(m => m?.id == n)
        if (marker) {
            let el = marker.getElement()
            el.className = el.className.replace('bg-blue-500', 'bg-green-500')
            marker.togglePopup()
        }
    }

})

watch(() => props.markers, () => loadMarkers())

let clusterIndex;

onMounted(() => {

    map = new mapboxgl.Map({
        container: mapRef.value, // container ID
        style: props.dark ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/light-v10',
        center: props.center || [-95.938458, 38.1462244], // starting position [lng, lat]
        zoom: 3, // starting zoom
        attributionControl: false
    });

    // map.addControl(
    //     new MapboxGeocoder({
    //         accessToken: mapboxgl.accessToken,
    //         mapboxgl
    //     })
    // );

    // let geolocate = new mapboxgl.GeolocateControl({
    //     positionOptions: {
    //         enableHighAccuracy: true
    //     },
    //     fitBoundsOptions: {
    //         maxZoom: 9
    //     },
    //     trackUserLocation: true,
    //     showUserHeading: true
    // })
    // map.addControl(geolocate);

    // const nav = new mapboxgl.NavigationControl();
    // map.addControl(nav, 'top-right');

    map.on('moveend', () => {
        setBounds()
        loadMarkers()
    });

	map.on("load", () => {

        if (props.geolocate) geolocate.trigger()
        loadMarkers()

            // Create a bounding box for the markers
            const bounds = new mapboxgl.LngLatBounds();
            props.markers.forEach(marker => {
                bounds.extend(marker.coordinates);
            });

        map.fitBounds(bounds, {
            padding: {top: 100, bottom: 100, left: 100, right: 100}
        });

	});

    if (route.query.startSearch) document.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0].focus()

});
</script>

<style lang="postcss">
.mapboxgl-map {
    font: inherit!important;
}

.mapboxgl-ctrl-top-right {
    z-index: 30;
}

.mapboxgl-ctrl,
.mapboxgl-ctrl-icon,
.mapboxgl-ctrl-compass,
.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-geolocate,
.mapboxgl-ctrl-geocoder--input {
    @apply dark:bg-gray-800 dark:!border-t-gray-600 dark:!text-gray-400
}

.mapboxgl-ctrl-geocoder--input,
.mapboxgl-ctrl-group {
    @apply rounded overflow-hidden
}

.mapboxgl-popup-anchor-right > .mapboxgl-popup-tip {
    @apply dark:!border-l-gray-800
}

.mapboxgl-popup-anchor-left > .mapboxgl-popup-tip {
    @apply dark:!border-r-gray-800
}

.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top > .mapboxgl-popup-tip {
    @apply dark:!border-b-gray-800
}

.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom > .mapboxgl-popup-tip {
    @apply dark:!border-t-gray-800
}

.mapboxgl-popup-anchor-top-right > .mapboxgl-popup-content > div {
    @apply !rounded-tr-none
}
.mapboxgl-popup-anchor-top-left > .mapboxgl-popup-content > div {
    @apply !rounded-tl-none
}
.mapboxgl-popup-anchor-bottom-right > .mapboxgl-popup-content > div {
    @apply !rounded-br-none
}
.mapboxgl-popup-anchor-bottom-left > .mapboxgl-popup-content > div {
    @apply !rounded-bl-none
}

.mapboxgl-popup {
    width: 100%;
    z-index: 20;
    display: none;
}

@media (min-width: 475px) {
    .mapboxgl-popup {
        display: flex;
    }
}

.mapboxgl-popup-content {
	text-align: center;
	font-family: inherit!important;
    padding: 0;
    border-radius: 0;
    background: transparent;
    width: 100%;
}
.cluster-marker {
    width: 24px;
    @apply text-2xs aspect-square flex items-center justify-center bg-blue-500 text-white rounded-full text-center p-1 font-semibold border border-white
}

</style>
