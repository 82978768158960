<template>
    <div class="w-full p-8 flex items-center">
        <Mango v-if="companyId" collection="companies" :id="companyId" @update:data="company = $event" :suspend="true" />
        <slot
            :logout="logout"
            :login="login"
            :createAccount="createAccount"
            :sendRecoveryEmail="sendRecoveryEmail"
            :resetPassword="resetPassword"
        >
            <form @submit.stop.prevent class="rounded-lg flex flex-col space-y-4 w-full max-w-sm">

                <template v-if="creatingAccount">
                    <div class="text-2xl">Start a Free Trial</div>
                    <div class="text-xs text-gray-500">Free trial lasts for 37 days or until you ink your first deal after which you will be charged $10/mo.</div>

                    <div class="flex gap-4 w-full">
                        <input type="text" v-model.trim="user.firstName" placeholder="First Name" />
                        <input type="text" v-model.trim="user.lastName" placeholder="Last Name" />
                    </div>

                    <input type="email" v-model.trim="user.email" placeholder="Email" />
                    <input type="password" v-model.trim="user.password" placeholder="Password" />

                    <input type="phone" v-model.trim="user.phone.number" placeholder="Phone Number" />
                    <label class="flex items-center justify-start w-full space-x-4">
                        <input type="checkbox" v-model.trim="user.phone.textable" />
                        <div class="w-full shrink-0 select-none">Enable SMS notifications?</div>
                    </label>

                    <div><input type="text" v-model="user.address.address" placeholder="Address" /></div>
                    <div class="space-x-4 flex">
                        <!-- <input type="text" v-model="user.phone.number" placeholder="Phone" /> -->
                        <input type="text" v-model="user.address.zip" placeholder="Zip Code" @input="extractZip" />
                    </div>
                    <div class="space-x-4 flex">
                        <input type="text" v-model="user.address.city" placeholder="City" />
                        <input type="text" v-model="user.address.state" placeholder="State" />
                    </div>

                </template>

                <template v-else-if="resettingPassword">
                    <div class="text-2xl">Update Password</div>
                    <input type="password" v-model.trim="user.password" placeholder="Password" v-if="!forgotPassword" />
                </template>

                <template v-else>
                    <img v-if="showLogo" :src="company?.logo?.url" class="w-full mb-12" />
                    <div class="text-2xl text-center">Login</div>
                    <input type="email" v-model.trim="user.email" placeholder="Email" />
                    <input type="password" v-model.trim="user.password" placeholder="Password" v-if="!forgotPassword" />
                </template>

                <button @click="action" class="px-3 py-2 bg-red-500 text-white rounded flex justify-center mx-auto">
                    <template v-if="!processing">{{ buttonText }}</template>
                    <Spinner v-else class="border-t-red-500 w-4 h-4 my-1" :small="true" color="border-t-white/50" />
                </button>

                <button v-if="store.user?.member?.id" @click="logout" class="text-xs text-gray-400 hover:underline">Logout</button>

                <div class="text-center select-none divide-x" >
                    <!-- <button v-if="!creatingAccount"     @click="creatingAccount = true; guest = false; forgotPassword = false" class="text-xs text-gray-400 hover:underline" data-test="create account">Create Account</button> -->
                    <button v-if="creatingAccount || resettingPassword"      @click="creatingAccount = false; guest = false; forgotPassword = false" class="text-xs text-gray-400 hover:underline" :class="{'dark:border-gray-500': resettingPassword}">{{ resettingPassword || (allowGuest && !guest) ? 'Login' : 'Have an account? Login instead.' }}</button>
                    <button v-if="forgotPassword" @click="creatingAccount = false; guest = false; forgotPassword = false" class="text-xs text-gray-400 hover:underline dark:border-gray-500">Remembered? Login Instead</button>
                    <button v-if="!creatingAccount && !resettingPassword && !forgotPassword"     @click="creatingAccount = false; guest = false; forgotPassword = true" class="text-xs text-gray-400 hover:underline dark:border-gray-500" data-test="forgot password" >Forgot Password?</button>
                </div>

            </form>
        </slot>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import { validateEmail } from '../../helpers/email'
import { getUser } from '../../helpers/user'

// Function for setting cookies
let setCookie = function (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default {
    props: {
        showLogo: {
            type: Boolean,
            default: true
        }
    },
    inject: ['store','axios'],
    data() {
        return {
            agreed: false,
            user: {
                email: null,
                password: null,
                firstName: null,
                lastName: null,
                phone: {
                    number: null,
                    textable: false,
                },
                address: {
                    name: null,
                    address: null,
                    city: null,
                    state: null,
                    zip: null,
                },
            },
            company: null,
            processing: false,
            creatingAccount: false,
            guest: false,
            forgotPassword: false,
        }
    },
    methods: {
        validateEmail,
        async login() {
            this.processing = true
            let member = (await this.axios.post(`${this.store.api}/controllers/account/login`, { email: this.user.email, password: this.user.password }))?.data

            if (member?.memberId) {
                if (member.roles?.includes('admin')) member.admin = true

                window.localStorage.setItem('user', member.memberId)
                window.localStorage.setItem('token', member.token)
                window.localStorage.setItem('email', this.user.email)
                window.localStorage.setItem('auth', `${member.token}:${member.memberId}`)

                setCookie(`Authorization`, `${member.token}:${member.memberId}`)
                this.axios.defaults.headers.common['Authorization'] = `${member.token}:${member.memberId}`

                const user = await getUser(null, member.memberId)
                this.store.user = user
                // if (user?.company?.id) this.store.theme = user.company

                this.$emit('loggedIn')

                let restrictedPath = this.store?.login?.next || '/'
                if (restrictedPath) this.$router.push(restrictedPath)

                this.$emit('hide')
            } else if (member?.invalidFields) {
                Swal.fire({ title: `Invalid ${member.invalidFields.join(', ')}`, icon: 'error' })
            }

            this.processing = false
        },
        logout() {
            this.store.user = {}
            window.user = null
            window.localStorage.removeItem('user')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('email')
            window.localStorage.removeItem('auth')
            setCookie(`Authorization`, ``)
            delete this.axios.defaults.headers.common['Authorization']
            this.$emit('loggedOut')
            this.$emit('hide')
            this.$router.push('/login')
        },
		async createAccount() {

			if (!this.agreed) return Swal.fire('You must agree to the terms and conditions.')
			if (!this.validateEmail(this.user.email)) return Swal.fire('Email must be a valid email.')
			if (this.user.password.length < 6 && !this.guest) return Swal.fire('Password must be at least 6 characters.')
			this.processing = true

			var data = {
				...this.user,
                title: `${this.user.firstName} ${this.user.lastName || ''}`.trim(),
                company: this.companyId || '63a1d5f115e332e736668e93',
                timezoneOffset: new Date().getTimezoneOffset()/60
			}

			let response = await this.axios.post(`${this.store.api}/members`, data)
            if (!response.data.success) {
                Swal.fire('Error', response.data.response, 'error')
            } else {
                await this.login()
            }
            this.$emit('accountCreated')
            this.$emit('hide')
            this.processing = false

        },
		async sendRecoveryEmail () {
			this.processing = true
			let response = await this.axios.post(`${this.store.api}/controllers/account/sendResetInstructions`, {email: this.user.email, forgot: true})
            if (response.data.success) Swal.fire('Success!', `Recovery instructions sent to: ${this.user.email}`, 'success')
            else Swal.fire('Invalid Email!', `The following user does not exist: ${this.user.email}`, 'warning')
            this.$emit('emailSent')
            this.$emit('hide')
            this.processing = false
		},
		async resetPassword () {
            if (this.user.password.length < 6) return Swal.fire('Password must be at least 6 characters.')
			this.processing = true

            let data = {
                email: this.$route.query.email,
                salt: this.$route.query.salt,
                password: this.user.password,
            }

			let response = await this.axios.post(`${this.store.api}/controllers/account/resetPassword`, data)
            if (response.data.success) {
                this.user.email = this.$route.query.email
                await this.login()
                this.$router.push({query: null})
                swal('Success!', 'Your password has been reset.', 'success')
            } else {
                swal('Invalid Link!', `The link you're using is invalid.`, 'error')
            }

            this.processing = false
            this.$emit('passwordReset')
            this.$emit('hide')
		},
    },
    computed: {
        guestPassword() { return Math.random().toString(36).substr(2)+Math.random().toString(36).substr(2) },
        labelText() { return this.resettingPassword ? 'Reset Password' : this.forgotPassword ? 'Recover Password' : this.creatingAccount ? this.guest ? 'Continue as Guest' : 'Create Account' : 'Login' },
        buttonText() { return this.resettingPassword ? 'Update Password' : this.forgotPassword ? 'Send Recovery Email' : this.creatingAccount ? this.guest ? 'Continue' : 'Create' : 'Login' },
        action() { return this.resettingPassword ? this.resetPassword : this.forgotPassword ? this.sendRecoveryEmail : this.creatingAccount ? this.createAccount : this.login },
        resettingPassword() { return !!this.$route.query?.salt },
        loggedIn() { return !!this.store.user?.id },
        companyId() {
            if (window.location.href.includes('bcrtrax.us')) return '63a1d5f115e332e736668e93'
            return this.$route.query?.company || this.$route.params?.company || '63a1d5f115e332e736668e93'
        },
    }
}
</script>


<style lang="postcss" scoped>
input {
    @apply border rounded outline-blue-400 px-3 py-2 w-full dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}
</style>
