<template>
    <div>
        <Modal @hide="$emit('hide')" maxWidth="max-w-xl">
            <div class="">
                <h2 class="text-2xl mb-4">Invite {{ roleHuman }}s</h2>
                <p class="mb-4">Enter the email addresses of the <strong>{{ roleHuman }}s</strong> you would like to invite to join <strong>{{ branch.title }}</strong>. They will receive an email with a link to join.</p>
                <div class="flex flex-col">
                    <Pills v-model="emails" :delimiters="[',',';']" placeholder="Copy/Paste Email Addresses" />
                </div>
                <div class="mt-8 flex justify-end">
                    <button class="px-3 py-2 bg-ncfic-green-600 dark:bg-ncfic-green-600/75 dark:text-white/75 text-white rounded" @click="sendInvites">Send Invites</button>
                </div>
            </div>
        </Modal>

        <Modal v-if="emailsToBeProcessed?.length" @hide="processing ? null : emailsToBeProcessed = []" :class="{'pointer-events-none': processing}" maxWidth="max-w-xl">
            <div>
                <h2 class="text-2xl mb-4">Inviting <strong>{{ roleHuman }}s</strong></h2>
                <p class="mb-4" v-if="processing">Please stay on the page while we send the invites.</p>
                <div class="divide-y dark:divide-gray-700">
                    <div v-for="e in emailsToBeProcessed" :key="e.email" class="flex items-center p-4" :class="{'opacity-50': e.status == 'pending'}">
                        <div class="mr-8">
                            <Spinner v-if="e.status == 'processing'" :small="true" class="w-8 h-8" />
                            <i class="fas fa-question text-gray-500" v-else-if="e.status == 'pending'" />
                            <i class="fas fa-check text-green-500" v-else-if="e.status == 'success'" />
                            <i class="fas fa-exclamation-triangle text-yellow-500" v-else-if="e.status == 'warning'" />
                            <i class="fas fa-times text-red-500" v-else-if="e.status == 'error'" />
                        </div>
                        <div>
                            <div>{{ e.email }}</div>
                            <div class="text-xs text-gray-500">{{ e.message }}</div>
                        </div>
                    </div>
                </div>
                <button v-if="!processing" class="px-3 py-2 bg-ncfic-blue text-white rounded float-right mt-8 text-sm" @click="processing ? null : emailsToBeProcessed = []">Done</button>
            </div>
        </Modal>
    </div>
</template>

<script>
import Mango from '../helpers/mango.js'
import Modal from '../components/layout/modal.vue'
import Pills from './pills.vue'
import Swal from 'sweetalert2'

export default {
    props: {
        role: {
            type: String,
            required: true
        },
        branch: {
            type: Object,
            required: true
        }
    },
	components: { Pills, Modal },
    data() {
        return {
            inviteFamiliesModalOpen: true,
            emails: [],
            emailsToBeProcessed: []
        }
    },
    watch: {
        emails(emails, old) {
            if (emails.join(',') === old.join(',')) return
            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
            // Filter valid email addresses from the array of strings
            const validEmails = emails
            .map(email => email.match(emailRegex)?.[0] || null)
            .filter(email => email !== null);
            // Check for invalid emails
            const invalidEmails = emails.filter(email => !email.match(emailRegex));
            if (invalidEmails.length) {
                Swal.fire({title: 'Invalid email addresses', text: `The following email addresses are invalid: ${invalidEmails.join(', ')}`, icon: 'warning'})
            }
            this.emails = Array.from(new Set(validEmails))
        }
    },
    methods: {
        async sendInvites() {
            // await
            this.inviteFamiliesModalOpen = false
            this.emailsToBeProcessed = this.emails.map(e => ({email: e, status: 'pending'}))
            for (let e of this.emailsToBeProcessed) {
                try {
                    e.status = 'processing'
                    let response = await Mango.members.save({
                        email: e.email,
                        branches: [this.branch],
                        roles: [this.role],
                        status: 'invited',
                        password: Math.random().toString(36),
                    })
                    console.log('response', response)
                    if (typeof(response) == 'string' && response?.includes('exists')) {
                        let member = (await Mango.members({search: {email: e.email}}))[0]
                        let existingRoles = member.roles || []
                        let roles = Array.from(new Set([...existingRoles, this.role]))
                        let existingBranches = member.branches || []
                        if (existingBranches.find(b => b.id === this.branch.id)) {
                            e.status = 'warning'
                            e.message = 'Member Already in Branch!'
                        } else {
                            let branches = [...existingBranches, {id: this.branch.id}]
                            let response = await Mango.members.save({id: member.id, roles, branches, status: 'open'})
                            if (!response.id) {
                                e.status = 'error'
                                e.message = response
                            } else {
                                this.$emit('added', {...response, email: e.email})
                                e.status = 'success'
                                e.message = 'Member Added!'
                            }
                        }
                    } else if (response?.id) {
                        this.$emit('added', {...response, email: e.email})
                        e.status = 'success'
                        e.message = 'Invitation Sent!'
                    } else {
                        e.status = 'error'
                        e.message = response
                    }
                } catch (err) {
                    console.log('err', err?.message || err)
                    e.status = 'error'
                }
                let emailIndex = this.emails.findIndex(e => e === e.email)
                this.emails.splice(emailIndex, 1)
            }
        }
    },
    computed: {
        roleHuman() {
            return this.role?.replace(/([A-Z])/g, ' $1')?.toTitleCase()
        },
        processing: function() {
            return this.emailsToBeProcessed?.some(e => e.status === 'pending' || e.status === 'processing')
        },
    }
}
</script>

<style>

</style>
