<template>
    <div class="w-full">
        <div v-if="data" class="flex flex-col items-center w-full mb-16">
            <div class="text-xs">
                <div>
                    <div class="uppercase text-xs font-bold opacity-40 mb-2">Metric</div>
                    <div class="flex gap-4 flex-wrap justify-center items-center mb-8">
                        <button
                            v-for="number in numberFields"
                            :key="number"
                            class="px-3 py-2 rounded border"
                            :class="target == number ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''"
                            @click="target = number"
                            v-html="number.toTitleCase()"
                        />
                    </div>
                </div>
                <div v-if="groupByOptions?.length > 1">
                    <div class="uppercase text-xs font-bold opacity-40 mb-2">Group By</div>
                    <div class="flex gap-4 flex-wrap items-center mb-8">
                        <button
                            v-for="option in groupByOptions"
                            :key="option"
                            class="px-3 py-2 rounded border"
                            :class="groupBy == option ? 'border-blue-500 text-blue-500 bg-blue-50/50' : ''"
                            @click="groupBy = option"
                            v-html="option?.toTitleCase()"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full max-w-5xl h-[25rem]">
                <Pie :data="data" :options="chartOptions" />
            </div>
        </div>
        <div v-else class="w-full max-w-5xl animate-pulse mx-auto">
            <div class="w-full flex justify-around items-center">
                <div class="flex flex-col space-y-2">
                    <div class="w-24 h-3 rounded bg-gray-100" v-for="i in Array.from({length: 15})" />
                </div>
                <div class="aspect-square min-h-[500px] bg-gray-100 rounded-full" />
            </div>
        </div>
        <!-- <Spinner v-else class="w-16 h-16 mx-auto" :small="true" /> -->
    </div>
</template>

<script>
import dayjs from 'dayjs'

let numberFields = [
    'knock',
    'talk',
    'walk',
    'contingency',
    'approvals',
    'contracts',
    'revenue',
    'softSets',
    'salesOpportunities',
]

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js'
import { Line, Pie } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
)

export default {
    components: {Line, Pie},
    props: ['reports', 'type'],
    inject: ['store'],
    data() {
        return {
            target: 'knock',
            numberFields,
            groupBy: this.type
        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        ylabel() {
            if (this.stat == 'KTWC') return
            if (this.stat == 'Conversion') return 'Percentage'
            if (this.stat == 'Revenue') return 'Dollars'
        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'left'
                    },
                    tooltip: {
                        callbacks: {
                            label: context => `${context.label}: ${context.raw}`
                        }
                    }
                },
                onClick: this.handleChartClick
            }
        },
        groupByOptions() {
            return this.type == 'branch' ? ['branch', 'entityType', 'region'] : ['rep', 'timeWithCompany', 'emoji']
        },
        data() {
            if (!this.reports?.length) return;
            let type = this.type

            let reports = this.reports?.flatMap(r => r[`${type}Reports`]);
            // console.log('reports', reports, type)

            let individuals = Array.from(new Set(reports.map(r => r?.[type].id))).map(id => {
                return {
                    ...reports.find(r => r?.[type].id == id)?.[type],
                    targetValue: reports.filter(r => r?.[type].id == id)
                        .map(r => r[this.target])
                        .reduce((a, b) => a + b, 0)
                };
            });

            if (this.type == 'branch' && this.groupBy != this.type) {
                individuals = Array.from(new Set(individuals.map(i => i[this.groupBy]))).map(group => {
                    return {
                        title: group?.toTitleCase() || 'Unknown',
                        id: group?.replace(/\s/g, '') || 'Unknown',
                        targetValue: individuals.filter(i => i[this.groupBy] == group)
                            .map(i => i.targetValue)
                            .reduce((a, b) => a + b, 0)
                    };
                });
            }

            if (this.type == 'rep' && this.groupBy != this.type) {
                let types = []
                let resolvers = {
                    'None': i => !i?.emoji?.length,
                    '🦁': i => i?.emoji?.includes('🦁') && !i?.emoji?.includes('🐋') && !i?.emoij?.includes('🦣'),
                    '🐋': i => i?.emoji?.includes('🐋') && !i?.emoij?.includes('🦣'),
                    '🦣': i => i?.emoji?.includes('🦣'),
                    '1yr+': i => dayjs().diff(dayjs(i.created), 'year') >= 1,
                    '6mo-1yr': i => dayjs().diff(dayjs(i.created), 'month') >= 6 && dayjs().diff(dayjs(i.created), 'year') < 1,
                    '3mo-6mo': i => dayjs().diff(dayjs(i.created), 'month') >= 3 && dayjs().diff(dayjs(i.created), 'month') < 6,
                    '1mo-3mo': i => dayjs().diff(dayjs(i.created), 'month') >= 1 && dayjs().diff(dayjs(i.created), 'month') < 3,
                    '2wk-1mo': i => dayjs().diff(dayjs(i.created), 'week') >= 2 && dayjs().diff(dayjs(i.created), 'month') < 1,
                    '2wk-': i => dayjs().diff(dayjs(i.created), 'week') < 2
                }
                if (this.groupBy == 'emoji') types = ['None', '🦁', '🐋', '🦣']
                if (this.groupBy == 'timeWithCompany') types = ['1yr+', '6mo-1yr', '3mo-6mo', '1mo-3mo', '2wk-1mo', '2wk-']
                individuals = types.map(group => {
                    return {
                        title: group?.toTitleCase() || 'Unknown',
                        id: group?.replace(/\s/g, '') || 'Unknown',
                        targetValue: individuals.filter(resolvers[group])
                            .map(i => i.targetValue)
                            .reduce((a, b) => a + b, 0)
                    };
                });
            }

            // Sort branches by target value
            if (this.groupBy != 'timeWithCompany') individuals.sort((a, b) => b.targetValue - a.targetValue);
            // Get the top 15 branches
            individuals = individuals.slice(0, 15);

            // Persistent color assignment with dynamic color generation
            if (!this.colorMap) {
                this.colorMap = new Map();
            }

            for (let b of individuals) {
                if (!this.colorMap.has(b.id)) {
                    this.colorMap.set(b.id, this.getColor(Array.from(this.colorMap).length));
                }
            }

            let datasets = [{
                backgroundColor: individuals.map(i => this.colorMap.get(i.id)),
                data: individuals.map(i => i.targetValue)
            }];

            let labels = individuals.map(i => i.title);

            return {
                labels,
                datasets
            };
        },
    },
    methods: {
        getColor(index) {

            let colors = [
                '#9575CD', // Medium Lavender
                '#64B5F6', // Medium Light Blue
                '#81C784', // Medium Light Green
                '#FFD54F', // Medium Yellow
                '#FF8A65', // Medium Orange
                '#BA68C8', // Medium Purple
                '#4DD0E1', // Medium Cyan
                '#FFB74D', // Medium Peach
                '#DCE775', // Medium Lime
                '#E57373', // Medium Pink
                '#90A4AE', // Medium Gray Blue
                '#FFEB3B', // Medium Amber
                '#B0BEC5', // Medium Blue Gray
                '#4FC3F7', // Medium Sky Blue
                '#A5D6A7', // Medium Mint
                '#FFF176', // Medium Lemon
                '#FF7043', // Medium Salmon
                '#7E57C2', // Medium Indigo
                '#FFCCBC', // Medium Coral
                '#F48FB1', // Medium Magenta
                '#AED581', // Medium Olive
                '#FFD54F', // Medium Gold
                '#FFCDD2', // Medium Rose
                '#7986CB', // Medium Periwinkle
                '#C5E1A5'  // Medium Lime Yellow
            ];
            // colors = []

            //

            // If the index is within the range of predefined colors, return the color from the list
            if (index < colors.length) {
                return colors[index];
            }

            // If the index exceeds the predefined colors, generate a random color
            return '#' + Math.floor(Math.random()*16777215).toString(16);
        },
        handleChartClick(event, elements) {
            if (elements.length > 0 && this.groupBy == this.type) {

                const index = elements[0].index;
                let reports = this.reports?.flatMap(r => r[`${this.type}Reports`]);
                let label = this.data.labels[index];
                let report = reports.find(r => r[this.type].title == label);

                console.log(`Clicked on ${label}: ${report[this.type].id}`);

                if (this.type == 'branch') {
                    this.$router.push(`/team-stats/${report.branch.id}`);
                } else {
                    this.$router.push(`/members/${report.rep.id}/report`);
                }

            }
        }
    }
}
</script>
