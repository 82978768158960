let roleMap = {
    salesRep: "sales_rep",
    salesManager: "sales_manager",
    partner: "partner",
    generalManager: "general_manager",
    projectManager: "production_manager",
}

const commissionTriggers = [
    {
        id: "freeUpgradeDrop",
        label: "Free Upgrade Drop",
        options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ],
        value: false,
        type: ["multiChoice"],
    },
    {
        id: "drpProgram",
        label: "DRP Program",
        options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
        ],
        value: false,
        type: ["multiChoice"],
    },
    {
        id: "drpAmount",
        label: "DRP Amount",
        value: 0,
        type: ["input", "currency"],
    },
    {
        id: "secondEmployeeType",
        label: "Second Employee Type",
        options: [
            { value: '', label: 'None' },
            { value: 'salesRep', label: 'Sales Rep' },
            { value: 'salesManager', label: 'Sales Manager' },
            { value: 'partner', label: 'Partner' },
            { value: 'generalManager', label: 'General Manager' },
            { value: 'productionManager', label: 'Production Manager' },
        ],
        value: "none",
        type: ["multiChoice"],
    },
];

const commissionRankings = [
    {
        id: "partner",
        type: "Partner",
        selfOriginated: 0.14,
        freeUpgradeDrop: 0,
        drpProgram: 0,
        leadGenerated: 0.14,
        commissionFactor: 0,
        deductibleSplit: 300,
    },
    {
        id: "general_manager",
        type: "General Manager",
        selfOriginated: 0.14,
        freeUpgradeDrop: -0.02,
        drpProgram: -0.03,
        leadGenerated: 0.12,
        commissionFactor: 0.6,
        deductibleSplit: 300,
    },
    {
        id: "sales_manager",
        type: "Sales Manager",
        selfOriginated: 0.11,
        freeUpgradeDrop: -0.02,
        drpProgram: -0.03,
        leadGenerated: 0.09,
        commissionFactor: 0.6,
        deductibleSplit: 300,
    },
    {
        id: "sales_trainer",
        type: "Sales Trainer",
        selfOriginated: 0.11,
        freeUpgradeDrop: -0.02,
        drpProgram: -0.03,
        leadGenerated: 0.09,
        commissionFactor: 0.6,
        deductibleSplit: 300,
    },
    {
        id: "production_manager",
        type: "Production Manager",
        selfOriginated: 0.09,
        freeUpgradeDrop: -0.01,
        drpProgram: -0.02,
        leadGenerated: 0.07,
        commissionFactor: 0.5,
        deductibleSplit: 250,
    },
    {
        id: "sales_rep",
        type: "Sales Rep",
        selfOriginated: 0.09,
        freeUpgradeDrop: -0.01,
        drpProgram: -0.02,
        leadGenerated: 0.07,
        commissionFactor: 0.5,
        deductibleSplit: 250,
    },
    {
        id: "solutionist",
        type: "Solutionist",
        selfOriginated: 0.09,
        freeUpgradeDrop: -0.01,
        drpProgram: -0.02,
        leadGenerated: 0.07,
        commissionFactor: 0.5,
        deductibleSplit: 250,
    },
];

const commissionItems = [
    {
        id: "deductible",
        label: "Deductible",
        value: 0,
        display: true,
        type: "currency",
        resolver: deductible,
    },
    {
        id: "totalProjectCost",
        label: "Total Project Cost",
        value: 0,
        display: true,
        type: "currency",
        resolver: totalProjectCost,
    },
    {
        id: "commissionPercentage",
        label: "Commission %",
        value: 0,
        display: true,
        type: "percentage",
        resolver: commissionPercentage,
    },
    {
        id: "B19",
        label: "B19",
        value: true,
        display: false,
        resolver: B19,
    },
    {
        id: "B20",
        label: "B20",
        value: false,
        display: false,
        resolver: B20,
    },
    {
        id: "B21",
        label: "B21",
        value: 0,
        display: false,
        resolver: B21,
    },
    {
        id: "C19",
        label: "C19",
        value: 0,
        display: false,
        resolver: C19,
    },
    {
        id: "C20",
        label: "C20",
        value: 0,
        display: false,
        resolver: C20,
    },
    {
        id: "C21",
        label: "C21",
        value: 0,
        display: false,
        resolver: C21,
    },
    {
        id: "commissionNet",
        label: "Commission Net",
        value: 0,
        display: true,
        type: "currency",
        resolver: commissionNet,
    },
    {
        id: "C23",
        label: "C23",
        value: 0,
        display: false,
        resolver: C23,
    },
    {
        id: "C24",
        label: "C24",
        value: 0,
        display: false,
        resolver: C24,
    },
    {
        id: "deductibleSplit",
        label: "Deductible Split",
        value: 0,
        display: true,
        type: "currency",
        resolver: deductibleSplit,
    },
    {
        id: "oAndPSplit",
        label: "O&P Split",
        value: 0,
        display: true,
        type: "currency",
        resolver: oAndPSplit,
    },
    {
        id: "warrantySold",
        label: "Warranty Sold",
        value: 0,
        display: true,
        type: "currency",
        resolver: warrantySold,
    },
    {
        id: "warrantyCommission",
        label: "Warranty Commission",
        value: 0,
        display: true,
        type: "currency",
        resolver: warrantyCommission,
    },
    {
        id: "drpCreditAmount",
        label: "DRP Credit Amount",
        value: 0,
        display: true,
        type: "currency",
        resolver: drpCreditAmount,
    },
    // Fix broken resolvers, then test repTotalPay
    {
        id: "repTotalPay",
        label: "Rep Total Pay",
        value: 0,
        display: true,
        type: "currency",
        resolver: (estimate) => {
            // return 0;
            return (
                commissionNet(estimate) +
                deductibleSplit(estimate) +
                oAndPSplit(estimate) +
                warrantyCommission(estimate)
            );
        },
    },
];

function deductible(estimate) {
    let value = (estimate.insuranceWork.deductible || 0);
    value = Math.ceil(value * 100) / 100;
    return value;
}

function commissionPercentage(estimate) {
    return estimate.jobDetails.leadType === "Lead Originated" ? 0.12 : 0.14;
}

function totalProjectCost(estimate) {
    let value;
    if (estimate.cashEstimate.cashEstimate) {
        value = estimate.cashEstimate.totalProjectCost;
    } else {
        value = estimate.insuranceWork.totalProjectCost;
    }

    // Round to the nearest cent
    value = Math.round(value * 100) / 100;
    return value;
}

function drpCreditAmount(estimate) {
    return estimate.commissionTriggers.drpAmount;
}

function warrantySold(estimate) {
    if (estimate.cashEstimate.cashEstimate) {
        return estimate.cashEstimate.warrantySold;
    } else {
        return estimate.insuranceWork.warrantySold;
    }
}

function B19(estimate) {
    return drpCreditAmount(estimate) > 500 && deductible(estimate) >= 500;
}

function B20(estimate) {
    return drpCreditAmount(estimate) < 500 && deductible(estimate) < 500;
}

function B21(estimate) {
    if (deductible(estimate) - drpCreditAmount(estimate) > 500) {
        return 500;
    } else {
        return deductible(estimate) - drpCreditAmount(estimate);
    }
}

function C19(estimate) {
    if (warrantySold(estimate) > 250) {
        return (
            totalProjectCost(estimate) -
            (warrantySold(estimate) - 250) -
            drpCreditAmount(estimate) -
            estimate.insuranceWork.oAndP
        );
    } else {
        return (
            totalProjectCost(estimate) -
            warrantySold(estimate) -
            drpCreditAmount(estimate)
        );
    }
}

function C20(estimate) {
    if (estimate.commissionTriggers.drpProgram) {
        return 0;
    } else if (deductible(estimate) > 499) {
        return 500;
    } else {
        return -deductible(estimate);
    }
}

function C21(estimate) {
    if (drpCreditAmount(estimate) > 500) {
        return drpCreditAmount(estimate);
    } else if (B21(estimate) === 500) {
        return 500;
    } else if (drpCreditAmount(estimate) < 500) {
        return drpCreditAmount(estimate) + B21(estimate);
    }
}

function commissionNet(estimate) {
    return (C19(estimate) - C20(estimate)) * commissionPercentage(estimate);
}

function C23(estimate) {
    // need to have employeeType pulled from Mango
    let employeeType = roleMap[estimate.author?.roles?.[0]];
    if (!employeeType) return null
    return commissionRankings.find((r) => r.id === employeeType).commissionFactor;
}

function C24(estimate) {
    // need to have employeeType pulled from Mango
    let employeeType = roleMap[estimate.author?.roles?.[0]];
    if (!employeeType) return null
    return commissionRankings.find((t) => t.id === employeeType).deductibleSplit;
}

function deductibleSplit(estimate) {
    if (drpCreditAmount(estimate) < 499) {
        return 0;
    } else if (B19(estimate)) {
        return (
            (deductible(estimate) - drpCreditAmount(estimate)) * C23(estimate)
        ) || 0;
    } else if (B20(estimate)) {
        return ((500 - drpCreditAmount(estimate)) * C23(estimate)) || 0;
    } else {
        console.log("Error in deductibleSplit!!! No condition met.");
        return 0;
    }
}

function oAndPSplit(estimate) {
    return estimate.insuranceWork.oAndP * C23(estimate);
}

function warrantyCommission(estimate) {
    if (warrantySold(estimate) > 250) {
        return (warrantySold(estimate) - 250) / 2;
    } else {
        return 0;
    }
}

export { commissionTriggers, commissionItems };
