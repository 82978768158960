<template>
    <div v-if="report" class="p-8 rounded-xl shadow-card space-y-4 md:space-y-6">

        <div class="w-full flex flex-col md:flex-row justify-between items-start border-b pb-6 text-center md:text-left">
            <div class="flex flex-col md:flex-row items-center w-full md:w-auto">
                <div
                    class="w-12 h-12 md:w-16 md:h-16 rounded-full shrink-0 bg-center bg-cover bg-white text-white"
                    :style="`background-image: url(https://bcr-rep-portal.s3.us-east-1.amazonaws.com/assets/companies/63a1d5f115e332e736668e93/icon.png?updated=1699898748707)`"
                />
                <div class="mt-4 md:mt-0 md:ml-4">
                    <div class="text-xl">Best Choice Roofing</div>
                    <div class="text-sm opacity-60">35 Executive Park Dr suite c, Hendersonville, TN</div>
                </div>
            </div>
            <router-link :to="`/companyReports/${report.id}`" class="hover:text-blue-500 text-lg md:text-2xl mt-4 md:mt-0 font-thin text-center w-full md:text-left md:w-auto">
                {{ dayjs(report.created).format('MMM D, YYYY') }}
            </router-link>
        </div>

        <div class="flex justify-between flex-wrap gap-2">
            <div class="text-xl flex space-x-2 justify-center md:justify-start px-3 py-2 rounded-lg bg-gray-100">
                <div class="w-sm text-lg">Revenue:</div>
                <div class="font-mono font-thin px-2">{{ projection.Revenue }}</div>
            </div>
            <div class="text-xl flex space-x-2 justify-center md:justify-start px-3 py-2 rounded-lg bg-gray-100">
                <div class="w-sm text-lg">MTD:</div>
                <div class="font-mono font-thin px-2">{{ projection.MTD || 0 }}</div>
            </div>
            <div class="text-xl flex space-x-2 justify-center md:justify-start px-3 py-2 rounded-lg bg-gray-100">
                <div class="w-sm text-lg">Tracking:</div>
                <div class="font-mono font-thin px-2">{{ projection.Tracking || 0 }}</div>
            </div>
        </div>

        <div>
            <div class="p-2">
                <!-- <div class="font-semibold text-xs tracking-xl uppercase opacity-40 text-center">Stats</div> -->
                <div class="text-sm md:mt-4 w-full flex flex-col justify-center">

                    <div v-if="ktwc.knock" class="flex space-x-4 font-mono mx-auto mb-4">
                        <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs tracking-widest uppercase leading-tight opacity-50">Knock</div>
                            <div class="">{{ ktwc.knock || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs tracking-widest uppercase leading-tight opacity-50">talk</div>
                            <div class="">{{ ktwc.talk || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs tracking-widest uppercase leading-tight opacity-50">walk</div>
                            <div class="">{{ ktwc.walk || 0 }}</div>
                        </div>
                        <div class="bg-red-50 w-14 h-14 md:w-16 md:h-16 rounded-xl border-2 flex flex-col items-center justify-center text-2xl relative">
                            <div class="text-xs tracking-widest uppercase leading-tight opacity-50">ctgy</div>
                            <div class="">{{ ktwc.contingency || 0 }}</div>
                        </div>
                    </div>

                    <div v-for="number, label in numbers" :key="label" class="flex justify-between items-center mt-1">
                        <span class="font-semibold text-xs md:text-sm opacity-80">{{ label }}: </span>
                        <div class="flex-grow mx-2 border-b border-dotted border-gray-500/50" />
                        <span class="font-mono md:text-base">{{ number }}</span>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
    props: {
        report: {type: Object, required: true},
    },
    methods: {
        dayjs
    },
    computed: {
        ktwc() {
            return {
                knock: this.report?.knock || 0,
                talk: this.report?.talk || 0,
                walk: this.report?.walk || 0,
                contingency: this.report?.contingency || 0
            }
        },
        numbers() {
            return {
                'Sales Opportunities': this.report?.salesOpportunities || 0,
                'Soft Sets': this.report?.softSets || 0,
                Contracts: this.report?.contracts || 0,
                Approvals: this.report?.approvals || 0,
                Estimates: (this.report?.estimates || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                'Conversion Rate': (((this.ktwc.contingency / this.ktwc.knock)*100) || 0).toFixed(0) + '%',
                'Knocks to Contingency': ((this.ktwc.knock / this.ktwc.contingency) || 0).toFixed(1),
            }
        },
        projection() {
            return {
                Revenue: (this.report?.revenue || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                MTD: (this.report?.mtd?.revenue || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
                Tracking: (this.report?.tracking || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }),
            }
        }
    }
}
</script>

<style>

</style>
