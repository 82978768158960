<template>
    <div class="w-full max-w-7xl h-auto mx-auto mb-6 rounded-lg overflow-hidden" :class="expanded ? 'shadow-lg' : ''">
        <button
            class="flex w-full justify-between p-4 h-14 bg-gray-100 hover:bg-gray-200 text-gray-600 hover:text-gray-800 font-semibold items-center transition-all duration-100"
            @click="expanded = !expanded"
        >
            <span class="text-left text-m">{{ title }}</span>
            <img v-if="!expanded" src="/images/keyboard_down_arrow.png" class="w-6 sm:w-8 h-auto" >
            <img v-else src="/images/keyboard_arrow_up.png" class="w-6 sm:w-8 h-auto">
        </button>
        <div
            v-show="expanded"
            class="sm:border-t bg-white"
            :class="[inputs ? 'grid items-center sm:p-6 px-2 py-4 text-sm' : '', small ? 'sm:grid-cols-2 lg:grid-cols-3' : 'md:grid-cols-2 xl:grid-cols-3']"
        >
            <slot>
                <div
                    v-for="value, key in inputs"
                    :key="key"
                    class="text-sm group px-4 py-2 h-full grid"
                    :class="[small ? 'sm:px-8 sm:border-l' : 'sm:px-6 sm:border-l', readOnlyFields.includes(key) ? 'cursor-default select-none' : 'sm:hover:text-blue-500']"
                >
                    <div class="flex flex-col sm:flex-row justify-between sm:items-center sm:space-x-2">
                        <span class="uppercase font-bold opacity-40 text-xs mb-1 sm:mb-0 sm:normal-case sm:font-normal sm:opacity-100 sm:text-sm">{{ inputData[key].label }}</span>
                        <div
                            class="w-full transition duration-300 ease-in-out rounded border border-gray-200 overflow-hidden shrink-0"
                            :class="[small ? 'bg-gray-200 hover:bg-gray-300 sm:w-28' : 'bg-white sm:w-56 md:w-48']"
                        >
                            <div v-if="readOnlyFields.includes(key)" class="sm:text-center px-2 py-1 w-full border-none bg-yellow-50">{{ value }}</div>
                            <select v-else-if="inputData[key].options" v-model="inputs[key]" class="sm:text-center px-2 py-1 w-full border-none bg-transparent">
                                <option v-for="option in inputData[key].options" :key="option?.id || option.value" :value="option?.value ?? option">{{ option?.label || option.type || option }}</option>
                            </select>
                            <input v-else :type="inputData[key].type || 'text'" v-model="inputs[key]" class="sm:text-center px-2 py-1 w-full border-none bg-transparent" :placeholder="inputData[key].label">
                        </div>
                    </div>
                </div>
            <!--
            <div v-else class="flex justify-between w-80 my-3">
                <span class="text-left px-2">{{ input.label }}</span>
                <select v-model="input.value" class="self-start text-center px-2 py-1 border-0 rounded bg-gray-200 p-2 w-28">
                    <option v-for="option in input.options" :key="option.value" :value="option.value">{{ option.label }}</option>
                </select>
            </div> -->
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title', 'inputs', 'small', 'expandedValue', 'inputData'],
    data() {
        return {
            expanded: false,
            readOnlyFields: ['perimeterLF', 'squares']
        }
    },
    watch: {
        expandedValue: {
            immediate: true,
            handler(value) {
                this.expanded = value;
            }
        }
    }
}
</script>

<style>

</style>
