<template>
    <router-link
        :to="type == 'branch' ? `/${branchPath}/${branch?.id}` : `/members/${rep?.id}/report`"
        class="flex items-center justify-start hover:-rotate-1 hover:scale-105 transition-all group space-x-6 w-full"
        :class="['closed','disabled'].includes(rep?.status) ? 'opacity-50' : 'opacity-100'"
    >
        <div
            :style="`background-image: url('${target.image?.url}')`"
            class="bg-cover bg-center w-20 h-20 shrink-0 rounded-full flex items-center justify-center bg-gray-100 border-2"
        ><i v-if="!target.image?.url" class="fad text-2xl" :class="rep ? 'fa-user' : 'fa-building'" />
        </div>
        <div class="flex flex-col">
            <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">{{ target.entityType?.toTitleCase() || rep?.roles?.join(',')?.replaceAll('sales', 'sales ') || `Joined: ${dayjs(target?.created).format('YYYY, MMM D')}` }}</div>
            <div class="text-lg font-semibold z-10 group-hover:underline">{{ target.title }}</div>
            <div v-if="report" class="text-xs font-bold text-black/50 rounded p-2 w-full" :class="average ? 'bg-green-100' : 'bg-red-100'">
                <div>{{ report?.knock || 0 }}-{{ report?.talk || 0 }}-{{ report?.walk || 0 }}-{{ report?.contingency || 0 }}</div>
                <div>{{report?.contingency || 0}}-{{report?.approvals || 0}}-{{report?.contracts || 0}}-{{revenue}}-{{report?.softSets || 0}}-{{report?.salesOpportunities || 0}}</div>
            </div>
            <div v-else class="w-full bg-gray-200 rounded h-5 animate-pulse" />
        </div>
    </router-link>
</template>

<script>
import dayjs from 'dayjs'

export default {
    props: ['branch', 'rep', 'companyReport'],
    methods: {
        dayjs
    },
    computed: {
        type() {
            return this.branch ? 'branch' : 'rep'
        },
        target() {
            return this.branch || this.rep
        },
        report() {
            return this.companyReport?.[`${this.type}Reports`]?.find(report => report?.[this.type]?.id === this.target?.id)
        },
        revenue() {
            return Math.round(this.report?.revenue || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })
        },
        average() {
            return (this.report?.contingency/this.report?.knock) > (this.companyReport?.contingency/this.companyReport?.knock)
        },
        branchPath() {
            return this.$route.path.includes('stats') ? 'team-stats' : 'my-team'
        }
    }
}
</script>

<style>

</style>
