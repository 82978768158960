<template>
    <div class="md:px-8 flex justify-center">
        <div class="flex flex-col md:flex-row justify-between w-full max-w-7xl md:py-32 text-center md:text-left relative" :class="searchEnabled ? 'pt-16 pb-8' : 'py-16'">

            <div v-if="logo" class="absolute" :class="image ? 'inset-y-0 left-0 w-1/2' : 'inset-0'">
                <div class="w-full h-full flex items-center justify-center p-8">
                    <!-- <img src="/images/logo.jpg" alt="Logo" class="w-full max-w-md opacity-5 -mt-16" /> -->
                </div>
            </div>

            <div v-if="image" class="w-full md:w-1/2 h-full md:h-[calc(100%+2rem)] absolute inset-y-0 right-0 bg-white dark:bg-black shadow-lg md:rounded-b-lg overflow-hidden">
                <div class="w-full h-full">
                    <div :style="`background-image: url(${image})`" class="w-full h-full bg-cover bg-center backdrop-blur-sm" />
                    <div class="w-full h-full backdrop-blur-sm md:backdrop-blur-0 bg-white/75 dark:bg-black/50 z-10 absolute inset-0" />
                </div>
            </div>

            <div class="z-10 px-4 md:px-0" :class="[!searchEnabled && !image && !subtitle ? 'w-full text-center' : 'md:w-1/2 !pr-12']">
                <h1
                    class="font-semibold text-red-900 dark:text-red-900"
                    :class="titleSize ? titleSize : title.length >= 15 ? 'text-4xl lg:text-5xl' : 'text-5xl lg:text-7xl'"
                >
                    {{ title }}
                </h1>
                <div class="lg:text-2xl text-gray-500 mt-4">{{ subtitle }}</div>
                <slot name="subtitle" />
            </div>

            <input
                v-if="!image && searchEnabled"
                type="text" name="search" v-model="search" placeholder="Search" ref="searchInput" autocomplete="off"
                class="
                    text-2xl border-b outline-none w-full mt-16 pb-4 px-4 z-10 bg-transparent text-center
                    md:text-left
                    md:w-1/2 md:mt-0 md:p-0 md:text-3xl
                    lg:w-1/3
                    dark:placeholder:text-gray-600 dark:border-gray-500/50 dark:border-b-2 dark:text-gray-400
                "
            />

        </div>

        <slot />
    </div>
</template>

<script>
import { useModel } from '../../helpers/model'
import Logo from '../../assets/logo.vue'

export default {
	props: {
        image: {type: String, default: ''},
        title: {type: String, required: true},
        titleSize: {type: String, required: false},
        subtitle: {type: String, default: ''},
        modelValue: {default: undefined},
        logo: {default: true},
    },
    components: {Logo},
	setup(props, { emit }) {
		return {
			search: useModel(props, emit, 'modelValue')
		}
	},
	methods: {
		focusInput() {
			this.$refs.searchInput.focus()
		}
	},
    computed: {
        searchEnabled() { return this.modelValue !== undefined }
    }
}
</script>
