<template>
    <div class="w-full p-8 md:p-16">

        <Mango
            v-slot="{loading}"
            @update:data="branches = $event"
            collection="branches"
            :id="null"
            :query="{
                search: admin ? {compareStatus: {not: 'closed'}} : {compareId: {in: store.user?.branches?.map(b => b.id)}, compareStatus: {not: 'closed'}},
                sort: {created: 1},
                limit: 1000,
                fields: ['title','image','entityType']
            }"
        >
            <h2 class="text-center text-2xl mb-8">{{ admin ? 'All' : 'My'}} Branches</h2>
            <Spinner v-if="loading" :small="true" class="w-16 h-16 mx-auto mt-16" />
            <div v-else class="flex flex-col items-center w-full">
                <div class="w-full flex justify-between space-x-8">

                    <div class="flex space-x-4 items-center mb-8 md:mb-16 col-span-3 3xl:col-span-2 flex-grow justify-center">
                        <input v-if="branches?.length >= 15" v-model="search" class="border rounded px-3 py-2 bg-white outline-none max-w-xl w-full" placeholder="Search" />
                    </div>

                </div>
                <div class="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-8 lg:gap-16 mt-8">
                    <router-link
                        v-for="branch in filteredBranches"
                        :key="branch.id"
                        :to="`/${path}/${branch?.id}`"
                        class="flex items-center justify-start hover:-rotate-1 hover:scale-105 transition-all group space-x-6 w-full"
                    >
                        <div
                            :style="`background-image: url('${branch.image?.url}')`"
                            class="bg-cover bg-center w-20 h-20 shrink-0 rounded-full flex items-center justify-center bg-gray-100 border-2"
                        ><i v-if="!branch.image?.url" class="fad text-2xl fa-building"/>
                        </div>
                        <div class="flex flex-col">
                            <div class="opacity-30 text-2xs uppercase tracking-widest font-bold">{{ branch.entityType?.toTitleCase() }}</div>
                            <div class="text-lg font-semibold z-10 group-hover:underline">{{ branch.title }}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </Mango>

    </div>
</template>

<script>
export default {
    inject: ['store','axios'],
    data() {
        return {
            branch: {},
            branches: [],
            search: null,
        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        },
        filteredBranches() {
            if (!this.search) return this.branches
            return this.branches.filter(b => b.title.toLowerCase().includes(this.search.toLowerCase()))
        },
        path() {
            return this.$route.path.includes('stats') ? 'team-stats' : 'my-team'
        }
    }
}
</script>

<style>

</style>
