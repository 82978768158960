let roles = {
    admin: 10,
    regionalManager: 3,
    partner: 3,
    owner: 3,
    branchManager: 2,
    generalManager: 2,
    salesManager: 2,
    projectManager: 2,
    officeAssistant: 2,
    salesRep: 1,
}

export default roles
