<template>
    <div class="md:col-span-2 lg:col-span-1 border dark:border-0 bg-white dark:bg-gray-800 rounded-lg overflow-hiden fix-rounding-safari h-full shadow-card max-h-[750px] w-full">
        <div class="flex flex-col lg:flex-row relative h-full w-full">
            <div class="p-4 md:p-8 w-full h-full right-0 top-0 overflow-scroll">
                <div class="uppercase font-semibold text-xs 2xl:text-sm text-gray-500 mb-4 flex justify-center items-center w-full">
                    <div class="uppercase font-semibold">Top Performers</div>
                    <!-- <div class="px-2 py-1 rounded bg-gray-100 dark:bg-gray-700 dark:text-gray-400 text-xs" >{{ dayjs().format('MMM D, YYYY') }}</div> -->
                </div>
                <div v-if="loading" class="p-8 flex justify-center"><Spinner :small="true" class="w-8 h-8" /></div>
                <div v-else-if="userNumbers && Object.keys(userNumbers)?.length" class="divide-y dark:divide-gray-700 overflow-scroll w-full">
                    <div v-for="user in userNumbers" :key="user.userId" class="flex justify-between w-full items-center py-2">
                        <div>
                            <router-link :to="`/members/${user.author?.id}/report`" class="hover:underline items-center">
                                <span>{{ user.author?.title }}</span>
                            </router-link>
                            <div v-if="user.author?.emoji" class="flex items-center space-x-2">
                                <div v-for="v, e in user.author.emoji.reduce((a,e) => {a[e] += 1; return a}, {'🦁':0,'🐋':0,'🦣':0})" :key="e" class="text-sm">
                                    <div v-if="v">{{ e }} {{v}}x</div>
                                </div>
                            </div>
                            <div class="text-xs opacity-50">{{ user?.author?.branches?.map(b => b.title)?.join(', ') }}</div>
                        </div>
                        <div class="flex-grow" />
                        <div>
                            <div></div>
                            <div>{{ Math.round(user.revenue).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }) }}</div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full h-full flex flex-col justify-center items-center p-8 text-center">
                    <div class="text-2xl font-bold opacity-20">No Contingincies Yet</div>
                    <div class="opacity-40 text-sm mt-2">Once contingincies are made, top performers will appear here.</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import Mango from '../helpers/mango'

export default {
    props: ['opportunities','finishedLoading', 'startRange'],
    data() {
        return {
            userNumbers: null,
            loading: true
        }
    },
    watch: {
        finishedLoading: {
            handler() {
                console.log('this.finishedLoading', this.finishedLoading)
                if (!this.finishedLoading) return
                this.getNumbers()
            },
            immediate: true
        }
    },
    async created() {

    },
    methods: {
        dayjs,
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            return new Date(param) > new Date(this.startRange)
        },
        async getNumbers() {

            // Create an object to track revenue and opportunities per user
            let userNumbers = {};

            // Iterate over each opportunity
            this.opportunities.filter(l => this.inRange(l, 'revenue')).forEach(opportunity => {
                // Add revenue for the opportunity's author
                if (opportunity.authorId) {
                    // if (typeof opportunity.authorId != 'string') console.log('opportunity', opportunity)
                    if (!userNumbers[opportunity.authorId]) {
                        userNumbers[opportunity.authorId] = { opportunities: 0, revenue: 0 };
                    }
                    userNumbers[opportunity.authorId].opportunities += 1;
                    userNumbers[opportunity.authorId].revenue += opportunity.revenue || 0;
                }

                // Add revenue for each user in the shotgun field
                if (opportunity.shotgun && Array.isArray(opportunity.shotgun)) {
                    opportunity.shotgun.forEach(user => {
                        if (!userNumbers[user.id]) {
                            userNumbers[user.id] = { opportunities: 0, revenue: 0 };
                        }
                        // Note: Only add revenue, not opportunities, for shotgun users
                        userNumbers[user.id].revenue += opportunity.revenue || 0;
                    });
                }
            });

            // Reduce to top 50 based on total revenue
            let sortedUserNumbers = Object.keys(userNumbers)
                .map(userId => ({ userId, ...userNumbers[userId] }))
                .sort((a, b) => b.revenue - a.revenue)
                .slice(0, 50);

            // Fetch user data
            let userIds = sortedUserNumbers.map(user => user.userId);
            let userData = await Mango.members({ search: { compareId: { in: userIds } }, limit: 50, fields: ['title', 'branches', 'emoji'] });

            console.log('userData', userData);

            // Map user data to revenue data
            this.userNumbers = sortedUserNumbers.map(user => ({
                author: userData.find(u => u.id == user.userId),
                ...user
            })).filter(u => u.revenue).sort((a, b) => b.revenue - a.revenue);

            this.loading = false;
        }
    },
    computed: {

    }
}
</script>

<style>

</style>
