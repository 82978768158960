<template>
    <!-- Don't add shrink-0 here or fitToContainer won't work -->
    <div class="overflow-hidden" ref="container">
        <div ref="content" :class="[space, {'flex-wrap': wrap}]" class="flex">

            <!-- Content -->
            <template v-if="people?.length">
                <div
                    v-for="person, index in people.slice(0, peopleLimit+1)"
                    :key="person.id"
                    class="rounded-full shrink-0 border-2 border-white dark:border-gray-400 bg-gray-600 uppercase text-white relative overflow-hidden flex items-center justify-center"
                    :class="size"
                    :to="index < peopleLimit ? `/resources/authors/${person.id}` : ''"
                >
                    <div v-if="index < peopleLimit">
                        <!-- <div v-if="person.title">{{ person?.title?.[0] + (person?.title?.includes(' ') ? person?.title?.split(' ')?.[1]?.[0] : '') }}</div> -->
                        <!-- <div v-else><i class="fa fa-question" /></div> -->
                        <i class="fad text-2xl fa-user" />
                        <div class="bg-cover bg-center w-full h-full absolute inset-0" :style="`background-image: url('${person?.image?.url?.replace('https://bcr-rep-portal.s3.amazonaws.com', '/s3')}')`" />
                        <div class="hidden dark:block w-full h-full absolute inset-0 bg-gray-800 opacity-20" />
                    </div>
                    <div v-else-if="index == peopleLimit" class="w-full h-full flex justify-center items-center" >
                        +{{ people.length - peopleLimit }}
                    </div>
                </div>
            </template>

            <!-- Skeleton -->
            <template v-else>
                <div
                    v-for="person, index in [...Array(peopleLimit < Infinity ? peopleLimit : 2).keys()]"
                    :key="index"
                    class="rounded-full shrink-0 border-2 border-white dark:border-gray-600 bg-gray-200 dark:bg-gray-700 relative overflow-hidden flex items-center justify-center"
                    :class="size"
                >
                    <div class="w-full h-full flex justify-center items-center" />
                </div>
            </template>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        people: {},
        limit: {default: Infinity},
        size: {default: 'h-12 w-12'},
        space: {default: '-space-x-4'},
        wrap: {default: false}
    },
    data() {
        return {
            peopleLimit: this.limit
        }
    },
    mounted() {
        this.fitToContainer();
        window.addEventListener("resize", this.fitToContainer);
    },
    unmounted() {
        window.removeEventListener("resize", this.fitToContainer);
    },
    methods: {
        fitToContainer() {
            // console.log('fitToContainer')
            if (this.wrap) return
            let containerWidth = this.$refs.container.clientWidth
            let contentWidth = this.$refs.content.scrollWidth

            if (containerWidth < contentWidth && this.peopleLimit) {
                this.peopleLimit = this.peopleLimit == Infinity ? 10 : this.peopleLimit >= 1 ? this.peopleLimit - 1 : this.peopleLimit = 0
                this.$nextTick(() => this.fitToContainer())
            }
        }
    }
}
</script>
