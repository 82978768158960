const materials = {
    "oc-supreme": {
        quantity: 33.3,
        unit: "Bundle",
        colors: [
            { label: "Autumn Brown", id: "autumn" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Desert Tan", id: "desert" },
            { label: "Brownwood", id: "brownwood" },
            { label: "Shasta White", id: "shasta" },
            { label: "Aspen Gray", id: "aspen" },
            { label: "Onyx Black", id: "black" },
            { label: "Estate Gray", id: "estate" },
            { label: "Weathered Wood", id: "weathered" },
            { label: "Teak", id: "teak" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "OC Supreme",
    },
    "oc-oakridge": {
        quantity: 33,
        unit: "Bundle",
        colors: [
            { label: "Black Walnut", id: "blackWalnut" },
            { label: "Brownwood", id: "brownwood" },
            { label: "Desert Tan", id: "desertTan" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Estate Gray", id: "estateGray" },
            { label: "Onyx Black", id: "onyxBlack" },
            { label: "Teak", id: "teak" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "OC Oakridge",
    },
    "oc-duration": {
        quantity: 33,
        unit: "Bundle",
        colors: [
            { label: "Desert Rose", id: "desert" },
            { label: "Slatestone Gray", id: "slatestone" },
            { label: "Williamsburg Gray", id: "williamsburg" },
            { label: "Brownwood", id: "brownwood" },
            { label: "Chateau Green", id: "chateau" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Estate Gray", id: "estate" },
            { label: "Midnight Plum", id: "midnight" },
            { label: "Onyx Black", id: "onyx" },
            { label: "Sand Castle", id: "sand" },
            { label: "Sierra Gray", id: "sierra" },
            { label: "Teak", id: "teak" },
            { label: "Terra Cotta", id: "terra" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "OC Duration",
    },
    "certainteed-landmark": {
        quantity: 33.33,
        unit: "Bundle",
        colors: [
            { label: "Burnt Sienna", id: "burntSienna" },
            { label: "Charcoal Black", id: "charcoalBlack" },
            { label: "Cobblestone Gray", id: "cobblestoneGray" },
            { label: "Colonial Slate", id: "colonialSlate" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Georgetown Gray", id: "georgetownGray" },
            { label: "Heather Blend", id: "heatherBlend" },
            { label: "Moire Black", id: "moireBlack" },
            { label: "Weathered Wood", id: "weatheredWood" },
            { label: "Resawn Shake", id: "resawnShake" },
            { label: "Hunter Green", id: "hunterGreen" },
            { label: "Granite Gray", id: "graniteGray" },
            { label: "Mojave Tan", id: "mojaveTan" },
            { label: "Black Walnut", id: "blackWalnut" },
            { label: "Silver Birch", id: "silverBirch" },
            { label: "Sunrise Cedar", id: "sunriseCedar" },
            { label: "MD Moire Black", id: "mdMoireBlack" },
            { label: "MD Weathered Wood", id: "mdWeatheredWood" },
            { label: "MD Georgetown Gray", id: "mdGeorgetownGray" },
            { label: "Cinder Black", id: "cinderBlack" },
            { label: "Terra Cotta", id: "terraCotta" },
            { label: "Painted Desert", id: "paintedDesert" },
            { label: "Thunderstorm Gray", id: "thunderstormGray" },
            { label: "Pewter", id: "pewter" },
            { label: "Pewterwood", id: "pewterwood" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "Certainteed Landmark",
    },
    "gaf-timberline-hdz": {
        quantity: 33.3,
        unit: "Bundle",
        colors: [
            { label: "Barkwood", id: "barkwood" },
            { label: "Birchwood", id: "birchwood" },
            { label: "Biscayne Blue", id: "biscayneBlue" },
            { label: "Charcoal", id: "charcoal" },
            { label: "Copper Canyon", id: "copperCanyon" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Fox Hollow Gray", id: "foxHollowGray" },
            { label: "Golden Amber", id: "goldenAmber" },
            { label: "Hickory", id: "hickory" },
            { label: "Hunter Green", id: "hunterGreen" },
            { label: "Mission Brown", id: "missionBrown" },
            { label: "Oyster Gray", id: "oysterGray" },
            { label: "Patriot Red", id: "patriotRed" },
            { label: "Pewter Gray", id: "pewterGray" },
            { label: "Shakewood", id: "shakewood" },
            { label: "Slate", id: "slate" },
            { label: "Sunset Brick", id: "sunsetBrick" },
            { label: "Weathered Wood", id: "weatheredWood" },
            { label: "White", id: "white" },
            { label: "Williamsburg Slate", id: "williamsburgSlate" },
            { label: "Appalachian Sky", id: "appalachianSky" },
            { label: "Cedar Falls", id: "cedarFalls" },
            { label: "Golden Harvest", id: "goldenHarvest" },
            { label: "Nantucket Morning", id: "nantucketMorning" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "GAF Timberline HDZ",
    },
    "oc-duration-storm-ir": {
        quantity: 33,
        unit: "Bundle",
        colors: [
            { label: "Antique Silver", id: "antiqueSilver" },
            { label: "Brownwood", id: "brownwood" },
            { label: "Desert Tan", id: "desertTan" },
            { label: "Driftwood", id: "driftwood" },
            { label: "Estate Gray", id: "estateGray" },
            { label: "Onyx Black", id: "onyxBlack" },
            { label: "Teak", id: "teak" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "OC Duration Storm IR",
    },
    "certainteed-land-climate-flex-ir": {
        quantity: 33.33,
        unit: "Bundle",
        colors: [
            { label: "Weathered Wood", id: "weatheredWood" },
            { label: "Burnt Sienna", id: "burntSienna" },
            { label: "Heather Blend", id: "heatherBlend" },
            { label: "Resawn Shake", id: "resawnShake" },
            { label: "Colonial Slate", id: "colonialSlate" },
            { label: "Moire Black", id: "moireBlack" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "Certainteed Land Climate Flex IR",
    },
    "gaf-timberline-as-ii-ir": {
        quantity: 33.3,
        unit: "Bundle",
        colors: [
            { label: "Barkwood", id: "barkwood" },
            { label: "Charcoal", id: "charcoal" },
            { label: "Hickory", id: "hickory" },
            { label: "Pewter Gray", id: "pewterGray" },
            { label: "Shakewood", id: "shakewood" },
            { label: "Slate", id: "slate" },
            { label: "Weathered Wood", id: "weatheredWood" },
        ],
        item: "Shingles",
        itemId: "shingles",
        type: "GAF Timberline AS ii IR",
    },
    "oc-supreme-hip-and-ridge-cap": {
        quantity: 33.3,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "OC Supreme",
    },
    "oc-proedge-33lf": {
        quantity: 33,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "OC ProEdge (33lf)",
    },
    "oc-duraridge-20-lf": {
        quantity: 20,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "OC Duraridge (20 lf)",
    },
    "oc-impact-ridge-storm-33-lf": {
        quantity: 33,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "OC Impact Ridge Storm (33 lf)",
    },
    "certainteed-shadow-ridge-30-lf": {
        quantity: 30,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "Certainteed Shadow Ridge (30 lf)",
    },
    "certainteed-shadow-ridge-cf-30-lf": {
        quantity: 30,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "Certainteed Shadow Ridge CF (30 lf)",
    },
    "gaf-sealaridge-25-lf": {
        quantity: 25,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "GAF Seal-A-Ridge (25 lf)",
    },
    "gaf-sealaridge-armour-25-lf": {
        quantity: 25,
        unit: "Bundle",
        item: "Hip & Ridge Cap",
        itemId: "hipAndRidgeCap",
        type: "GAF Seal-A-Ridge Armour (25 lf)",
    },
    "oc-starter-strip-100-lf": {
        quantity: 105,
        unit: "Bundle",
        item: "Starter",
        itemId: "starter",
        type: "OC Starter Strip (100 lf)",
    },
    "certainteed-swiftstart-1163-lf": {
        quantity: 116,
        unit: "Bundle",
        item: "Starter",
        itemId: "starter",
        type: "Certainteed Swiftstart (116.3 lf)",
    },
    "gaf-pro-start-120-lf": {
        quantity: 120,
        unit: "Bundle",
        item: "Starter",
        itemId: "starter",
        type: "GAF Pro Start (120 lf)",
    },
    "oc-weatherlock": {
        quantity: 66,
        unit: "Roll",
        item: "Ice & Water",
        itemId: "iceAndWater",
        type: "OC Weatherlock",
    },
    "certainteed-winterguard": {
        quantity: 66,
        unit: "Roll",
        item: "Ice & Water",
        itemId: "iceAndWater",
        type: "Certainteed Winterguard",
    },
    "gaf-weatherwatch": {
        quantity: 66,
        unit: "Roll",
        item: "Ice & Water",
        itemId: "iceAndWater",
        type: "GAF Weatherwatch",
    },
    "oc-rhino": {
        quantity: 10,
        unit: "Roll",
        item: "Underlayment",
        itemId: "underlayment",
        type: "OC Rhino",
    },
    "bcr-logo": {
        quantity: 5,
        unit: "Roll",
        item: "Underlayment",
        itemId: "underlayment",
        type: "BCR Logo",
    },
    "certainteed-roof-runner": {
        quantity: 10,
        unit: "Roll",
        item: "Underlayment",
        itemId: "underlayment",
        type: "Certainteed Roof Runner",
    },
    "gaf-felt-buster": {
        quantity: 10,
        unit: "Roll",
        item: "Underlayment",
        itemId: "underlayment",
        type: "GAF Felt Buster",
    },
    "aluminum-2x2-painted": {
        quantity: 10,
        unit: "Each",
        item: "Drip Edge",
        itemId: "dripEdge",
        type: "Aluminum 2x2 Painted",
    },
    "oc-ventsure-4ft-strip": {
        quantity: 4,
        unit: "Each",
        item: "Ridge Vent",
        itemId: "ridgeVent",
        type: "OC Ventsure 4Ft Strip",
    },
    "certainteed-ridge-vent-4ft-strip": {
        quantity: 4,
        unit: "Each",
        item: "Ridge Vent",
        itemId: "ridgeVent",
        type: "Certainteed Ridge Vent 4Ft Strip",
    },
    "gaf-cobra-ridge-vent-4ft-strip": {
        quantity: 4,
        unit: "Each",
        item: "Ridge Vent",
        itemId: "ridgeVent",
        type: "GAF Cobra Ridge Vent 4Ft Strip",
    },
    "lomanco-750-metal": {
        quantity: 1,
        unit: "Each",
        item: "Box Vent",
        itemId: "boxVent",
        type: "Lomanco 750 Metal",
    },
    "power-vent-cover": {
        quantity: 1,
        unit: "Each",
        item: "Power Assisted Vent",
        itemId: "powerAssistedVent",
        type: "Replace Cover Only",
    },
    "125--3-neoprene": {
        quantity: 1,
        unit: "Each",
        item: "Pipe Boots",
        itemId: "pipeBoots",
        type: '1.25" - 3" Neoprene',
    },
    "3--4-neoprene": {
        quantity: 1,
        unit: "Each",
        item: "Pipe Boots",
        itemId: "pipeBoots",
        type: '3" - 4" Neoprene',
    },
    "electric-split-boot": {
        quantity: 1,
        unit: "Each",
        item: "Electric Split Boot",
        itemId: "electricSplitBoot",
        type: "Electric Split Boot",
    },
    "lead-pipe-boot": {
        quantity: 1,
        unit: "Each",
        item: "Lead Pipe Boot",
        itemId: "leadPipeBoot",
        type: "Lead Pipe Boot",
    },
    "wall-flashing-4x4x10": {
        quantity: 10,
        unit: "Each",
        item: "Wall Flashing",
        itemId: "wallFlashing",
        type: "Wall Flashing 4x4x10",
    },
    "step-flashing-4x4x8": {
        quantity: 33,
        unit: "Bundle",
        item: "Step Flashing",
        itemId: "stepFlashing",
        type: "Step Flashing 4x4x8",
    },
    "counter-flashing": {
        quantity: 10,
        unit: "Each",
        item: "Counter Flashing",
        itemId: "counterFlashing",
        type: "Counter Flashing",
    },
    "coil-nails-1-14": {
        quantity: 1,
        unit: "Box",
        item: "Coil Nails",
        itemId: "coilNails",
        type: 'Coil Nails 1 1/4"',
    },
    "coil-nails-1-12": {
        quantity: 1,
        unit: "Box",
        item: "Coil Nails",
        itemId: "coilNails",
        type: 'Coil Nails 1 1/2"',
    },
    "button-cap-nails-1-inch": {
        quantity: 1,
        unit: "Box",
        item: "Button Cap Nails",
        itemId: "buttonCapNails",
        type: "Button Cap Nails 1 Inch",
    },
    osb: {
        quantity: 1,
        unit: "Each",
        item: "Decking",
        itemId: "decking",
        type: "OSB",
    },
    "geo-cel-sealant": {
        quantity: 1,
        unit: "Each",
        item: "Caulking",
        itemId: "caulking",
        type: "GEO CEL Sealant",
    },
    "mule-hide-top-shield-pro": {
        quantity: 1,
        unit: "Roll",
        item: "Cap Sheet",
        itemId: "capSheet",
        type: "Mule Hide Top Shield Pro",
    },
    "mule-hide-top-shield-pro-base": {
        quantity: 1,
        unit: "Roll",
        item: "Base Sheet",
        itemId: "baseSheet",
        type: "Mule Hide Top Shield Pro Base",
    },
    "aluminum-2x4-painted": {
        quantity: 10,
        unit: "Each",
        item: "XL Drip Edge",
        itemId: "xlDripEdge",
        type: "Aluminum 2x4 Painted",
    },
    "broan-636": {
        quantity: 1,
        unit: "Each",
        item: "Broan Vent",
        itemId: "broanVent",
        type: "Broan 636",
    },
    // "other": {
    //     quantity: '',
    //     unit: '',
    //     item: "Other",
    //     itemId: "other",
    //     type: "Other",
    // },
};

const materialResolvers = {
    shingles: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(
                ((estimate.dimensions.totalRoofArea -
                    estimate.dimensions.baseCapSheet) *
                    (1 + estimate.details.waste / 100)) /
                unitQty
            )
            : 0;
        return quantity;
    },
    hipAndRidgeCap: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(
                ((estimate.dimensions.hipsLF + estimate.dimensions.ridgesLF) /
                    unitQty) *
                (1 + estimate.details.waste / 100)
            )
            : 0;
        return quantity;
    },
    starter: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(
                (estimate.dimensions.eavesLF + estimate.dimensions.rakesLF) /
                unitQty
            )
            : 0;
        return quantity;
    },
    iceAndWater: (estimate, unitQty) => {
        const includeIceAndWaterOnEaves =
            estimate.details.includeIceAndWaterOnEaves;
        let quantity;
        if (includeIceAndWaterOnEaves) {
            quantity = unitQty
                ? Math.ceil(
                    (estimate.dimensions.valleysLF +
                        estimate.dimensions.eavesLF) /
                    unitQty +
                    estimate.dimensions.eaveExp / 200
                )
                : 0;
        } else {
            quantity = unitQty
                ? Math.ceil(
                    estimate.dimensions.valleysLF / unitQty +
                    estimate.dimensions.eaveExp / 200
                )
                : 0;
        }
        return quantity;
    },
    underlayment: (estimate, unitQty) => {
        if (!unitQty) return 0;

        const pitch = estimate.dimensions.roofPitch;
        const isLowPitch = pitch === "2/12" || pitch === "3/12";
        const adjustedSquares =
            estimate.dimensions.squares -
            ((estimate.dimensions.valleysLF + estimate.dimensions.eavesLF) * 3 +
                estimate.dimensions.eaveExp) /
            100;
        const quantity = Math.ceil(
            (adjustedSquares / unitQty) * (1 + estimate.details.waste / 100)
        );

        return isLowPitch ? quantity * 2 : quantity;
    },
    dripEdge: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(
                (estimate.dimensions.eavesLF +
                    estimate.dimensions.rakesLF -
                    estimate.dimensions.xlDripEdgeArea +
                    10) /
                unitQty
            )
            : 0;
        return quantity;
    },
    ridgeVent: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(estimate.dimensions.ridgeVentLF / unitQty)
            : 0;
        return quantity;
    },
    boxVent: (estimate, unitQty) => {
        return estimate.materials.boxVent750;
    },
    powerAssistedVent: (estimate, unitQty) => {
        return estimate.structures.powerVentCover;
    },
    pipeBoots: (estimate, unitQty) => {
        return estimate.materials.pipeBoots;
    },
    electricSplitBoot: (estimate, unitQty) => {
        return estimate.materials.electricSplitBoot;
    },
    leadPipeBoot: (estimate, unitQty) => {
        return estimate.materials.leadPipeBoot;
    },
    wallFlashing: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(estimate.dimensions.wallFlash / unitQty)
            : 0;
        return quantity;
    },
    stepFlashing: (estimate, unitQty) => {
        let quantity = unitQty
            ? Math.ceil(estimate.dimensions.stepFlash / unitQty)
            : 0;
        return quantity;
    },
    counterFlashing: (estimate, unitQty) => {
        return estimate.laborItems.counterFlashing;
    },
    coilNails: (estimate, unitQty) => {
        return Math.ceil(estimate.dimensions.squares / 15);
    },
    buttonCapNails: (estimate, unitQty) => {
        return Math.ceil(estimate.dimensions.squares / 15);
    },
    decking: (estimate, unitQty) => {
        return estimate.materials.decking;
    },
    caulking: (estimate, unitQty) => {
        return estimate.materials.supplyCaulk
            ? Math.ceil(estimate.dimensions.squares / 10)
            : 0;
    },
    capSheet: (estimate, unitQty) => {
        return Math.ceil(estimate.dimensions.baseCapSheet / 100);
    },
    baseSheet: (estimate, unitQty) => {
        return Math.ceil(estimate.dimensions.baseCapSheet / 150);
    },
    xlDripEdge: (estimate, unitQty) => {
        return Math.ceil(estimate.dimensions.xlDripEdgeArea / 10);
    },
    broanVent: (estimate, unitQty) => {
        return estimate.structures.broanVents;
    },
};

let materialItems = Array.from(
    new Set(Object.keys(materials).map((m) => materials[m].itemId))
);
for (let id of materialItems) {
    if (!materialResolvers[id])
        console.log("Missing Resolver For Material Item: ", id);
}

export { materials, materialResolvers, materialItems };
