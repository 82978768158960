import dayjs from "dayjs";
import fetchImageAsBase64 from "./fetchImageAsBase64";
import formatPhone from './formatPhone';

async function createVcard({ member, image }) {

    // Make sure we have a name and at least a phone or email
    let phone = formatPhone(member.phone)
    let email = member.email

    if (!member.firstName || !member.lastName || !(phone || email)) return

    member.phone = phone
    member.email = email

    if (member?.birthday) {

        const formats = [
            'YYYY/MM/DD', 'YYYY-MM-DD', // Year first
            'MM/DD/YYYY', 'MM-DD-YYYY', // Month first, four-digit year
            'MM/DD/YY', 'MM-DD-YY',   // Month first, two-digit year
            'DD/MM/YYYY', 'DD-MM-YYYY', // Day first, four-digit year
            'DD/MM/YY', 'DD-MM-YY',   // Day first, two-digit year
            'MM/DD', 'MM-DD',      // Month and day only
            'MMMM D, YYYY', // "February 12, 1994"
            'MMMM Do',      // "January 1st"
            'MMMM D',      // "January 1st"
        ];

        let parsedDate;

        // Try each format until one is successfully parsed
        for (const format of formats) {
            parsedDate = dayjs(member.birthday, format);
            if (parsedDate.isValid()) break;
        }

        if (parsedDate && parsedDate.isValid()) {
            if (parsedDate.format('YYYY') === '1900') {
                // If the year is 1900, assume it was not provided
                member.birthday = `--${parsedDate.format('MM-DD')}`;
            } else {
                member.birthday = `${parsedDate.format('YYYY-MM-DD')}`;
            }
        }
    }

    let imageBase64 = '';
    if (image || member.image?.url) {
        if (image) {
            try {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0);
                imageBase64 = canvas.toDataURL('image/jpeg').split(',')[1];
            } catch {
                imageBase64 = await fetchImageAsBase64(member.image?.url);
            }
        } else {
            imageBase64 = await fetchImageAsBase64(member.image?.url);
        }
    }


    const addressField = member?.address ? `${member.address.address};${member.address.city};${member.address.state};${member.address.zip};${member.address.country || 'USA'}` : '';

    let vCardData = `BEGIN:VCARD\nVERSION:3.0\nFN:${member.firstName} ${member.lastName}\nN:${member.lastName};${member.firstName}`
    vCardData += `\nORG:Best Choice Roofing`;

    let jobTitle = member?.roles?.[0]?.replace(/([A-Z])/g, ' $1')?.toTitleCase()
    if (jobTitle) vCardData += `\nTITLE:${jobTitle}`; // Add job title

    if (member.email) vCardData += `\nEMAIL:${member.email}`
    if (member.phone) vCardData += `\nTEL:${member.phone}`
    if (member.birthday) vCardData += `\nBDAY:${member.birthday}`
    // if (member?.image?.url) vCardData += `\nPHOTO;TYPE=JPEG;VALUE=URI:${member.image.url}`
    if (imageBase64) vCardData += `\nPHOTO;ENCODING=b;TYPE=JPEG:${imageBase64}`
    if (addressField) vCardData += `\nADR;TYPE=HOME:;;${addressField}`


    vCardData += `\nEND:VCARD`

    return vCardData

}

export default createVcard
