<template>
    <div ref="search" id="search" />
</template>

<script>
import { mapboxAccessToken } from "../../../../config/config/settings.json"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import mapboxgl from "mapbox-gl"

export default {
    mounted() {
        mapboxgl.accessToken = mapboxAccessToken

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            types: 'country,region,place,postcode,locality,neighborhood'
        });

        geocoder.addTo(this.$refs.search)
        geocoder.on('result', (e) => this.$emit('result', e.result));
    }
}
</script>

<style lang="postcss" scoped>
:deep(input) {
    @apply w-full shadow-none h-full
}
:deep(.mapboxgl-ctrl-geocoder) {
    @apply shadow-none w-full max-w-full h-full
}
:deep(.mapboxgl-ctrl-geocoder--input:focus) {
    @apply outline outline-2 outline-offset-8 outline-blue-500 rounded
}
</style>
