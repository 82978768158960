<template>
    <div>
        <div class="flex justify-center items-center">
            <div v-if="docTitle == 'Customer Quote'" class="flex items-center px-2 py-1 bg-gray-50 border rounded space-x-2">
                <button @click="exportPDF(true)" class="text-sm text-red-600 underline py-1 px-2 rounded items-center ml-2 mb-1">{{ docTitle }}</button>
                <div class="text-xs opacity-50">Amounts</div>
                <Toggle v-model="showCustomerAmounts" background="bg-gray-200" :small="true" />
            </div>
            <button v-else @click="exportPDF(true)" class="text-sm text-red-600 underline py-1 px-2 rounded items-center ml-2 mb-1">{{ docTitle }}</button>
        </div>
        <Modal :active="showDoc" @hide="showDoc = false" dialogClasses="!p-0 !max-h-screen" maxWidth="!max-w-[8.5in]">
            <!-- Modal Content change width back to 4/12 -->
            <div class="modal-pdf w-full" :id="docTitle" ref="pdfContent" @dblclick="exportPDF(true)">
                <!-- bg-white py-4 rounded-lg shadow-lg z-10 w-5/12 h-[90vh] mb-20 -->
                <div v-if="header != 'bw'">
                    <div class="bg-red-600 mb-4 h-8 flex items-center justify-center"><h2 class="text-white text-lg font-bold pb-4">{{ docTitle }}</h2></div>
                    <div class="w-full px-6">
                        <div class="flex justify-between ">
                            <div class="text-center w-40" >
                                <img class="w-36 h-28 " :src="absoluteLogoPath" alt="">
                                <div class="text-xs my-2">
                                    <!-- Replace with Company Address from Mango -->
                                    <div>{{ opportunity.branch?.address?.address }}</div>
                                    <div>{{ opportunity.branch?.address?.city }}, {{ opportunity.branch?.address?.state }} {{ opportunity.branch?.address?.zip }}</div>
                                </div>
                            </div>
                            <div class="w-36">
                                <div>
                                    <div class="text-sm">Sales Representative</div>
                                    <hr class="border-black w-full mt-2">
                                    <!-- Pull from Mango eventually... -->
                                    <div class="text-xs" v-html="salesReps"/>
                                </div>
                                <div>
                                    <div class="text-sm mt-6">Customer Info</div>
                                    <hr class="border-black w-full mt-2">
                                    <div class="text-xs mt-2">
                                        <div>{{ estimate.jobDetails.customerName }}</div>
                                        <div>{{ estimate.jobDetails.street }}</div>
                                        <div>
                                            <span>{{ estimate.jobDetails.city }}, </span>
                                            <span>{{ estimate.jobDetails.state }}&nbsp;</span>
                                            <span>{{ estimate.jobDetails.zip }}</span>
                                        </div>
                                        <div>{{ estimate.jobDetails.email }}</div>
                                        <div>{{ formatPhone(estimate.jobDetails.phone) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="w-full">
                    <img class="w-24 float-left grayscale" :src="absoluteLogoPath" alt="">
                    <div class="w-full text-center">
                        <div class="font-bold text-xl uppercase">{{ docTitle }} </div>
                        <div class="font-semibold mt-2">Existing Roofing Details</div>
                    </div>
                </div>
                <slot></slot>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '../layout/modal.vue';
// import html2canvas from 'html2canvas'
// import jsPDF from 'jspdf'
// import pdfMake from 'pdfmake'
import html2pdf from 'html2pdf.js'
import formatPhone from '../../helpers/formatPhone';
import Toggle from './toggle.vue';

export default {
    props: ['docTitle', 'estimate', 'opportunity', 'header'],
	components: { Modal, Toggle },
    data() {
        return {
            showDoc: false,
            showCustomerAmounts: false,
        }
    },
    watch: {
        showCustomerAmounts(n) {
            this.$emit('showCustomerAmounts', n)
        }
    },
    methods: {
        formatPhone,
        savePDF() {
            // // let activityPage = document.getElementById('activity-page')
            // let activityPage = this.$refs.pdf
            // let filename = this.docTitle?.toLowerCase()?.trim()?.replace(/[^a-zA-Z0-9\s]/g, '')?.replace(/\s/g, '-') + '-activity-page.pdf'

            // html2canvas(activityPage, {scale: 4}).then(function(canvas) {
            //     let imgData = canvas.toDataURL('image/png');
            //     let pdf = new jsPDF('p', 'mm', 'letter');

            //     let imgWidth = pdf.internal.pageSize.getWidth();
            //     let imgHeight = canvas.height * imgWidth / canvas.width;

            //     pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, null, 'FAST');
            //     pdf.save(filename);
            // });

            var docDefinition = {
                content: [
                    {
                        text: this.$refs.pdf.innerHTML,
                        style: 'header'
                    }
                ],
                defaultStyle: {
                    font: 'Roboto'
                }
            };
            pdfMake.createPdf(docDefinition).download('file.pdf');


        },
        async exportPDF(openInNewTab) {
            try {
                let element = this.$refs.pdfContent;

                // element.style.padding = "0";
                // element.style.margin = "0";

                // Use html2pdf options
                let opt = {
                    margin:       .1,
                    filename:     `${this.docTitle}.pdf`,
                    image:        { type: 'jpeg', quality: 1 },
                    html2canvas:  { dpi: 192, scale: 4, letterRendering: true, useCORS: true},
                    jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
                };

                // Generate PDF
                // html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
                //     var iframe = document.createElement('iframe');
                //     iframe.style.display = 'none';
                //     document.body.appendChild(iframe);
                //     iframe.src = URL.createObjectURL(pdf.output('blob'));

                //     // Open the PDF in a new tab
                //     window.open(iframe.src);
                // });

                let pdf = await html2pdf().set(opt).from(element).toPdf().get('pdf')

                if (openInNewTab) {
                    var iframe = document.createElement('iframe');
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);
                    iframe.src = URL.createObjectURL(pdf.output('blob'));

                    // Open the PDF in a new tab
                    window.open(iframe.src);
                } else {
                    let data = pdf.output('blob')
                    let file = new File([data], `${this.docTitle}.pdf`, {type: 'application/pdf'})
                    return file
                }



            } catch (error) {
                console.error("Error exporting PDF:", error);
            }
        },
    },
    computed: {
        salesReps() {
            let op = this.opportunity
            if (!op?.id) return []
            let reps = [op.author, ...(op.shotgun||[])]
            return reps.map(r => `${r.firstName} ${r.lastName}`).join('<br>')
        },
        absoluteLogoPath() {
            return `${window.location.origin}/images/bcr-main-logo.png`;
        }
    },
}
</script>

<style lang="postcss">
  .modal-pdf {
    background-color: white;
    padding: 20px;
    /* Adjust width and max-height as needed */
    /* Example width 612 */
    /* Example height 792 */
    overflow-y: auto; /* Allows scrolling within the modal if content exceeds its max-height */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds some shadow for better visibility */
    z-index: 11; /* Ensure modal content is above the overlay */
    margin: auto; /* This is crucial for centering */
  }
  .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Ensure it's above other content */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: for the overlay */
  }
</style>
