const inputs = {
    totalRoofArea: {
        category: "dimensions",
        type: "tel",
        label: "Total Roof Area SF",
        value: 4975,
    },
    facets7_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 7/12 (SF)",
        value: 3210,
    },
    facets8_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 8/12 (SF)",
        value: 0,
    },
    facets9_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 9/12 (SF)",
        value: 0,
    },
    facets10_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 10/12 (SF)",
        value: 750,
    },
    facets11_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 11/12 (SF)",
        value: 0,
    },
    facets12_12: {
        category: "dimensions",
        type: "tel",
        label: "Steep Facets 12/12 (SF)",
        value: 0,
    },
    extraLayers: {
        category: "details",
        type: "tel",
        label: "Extra Shingle Layers to Remove",
        value: 0,
    },
    chargedTrips: {
        category: "details",
        type: "tel",
        label: "Charged Trips Required",
        value: 0,
    },
    gutters: {
        category: "structures",
        label: "Remove Gutters",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    noAccessSQ: {
        category: "dimensions",
        type: "tel",
        label: "No access SQ",
        value: 0,
    },
    extFeltLayers: {
        category: "details",
        type: "tel",
        label: "Extra felt layers to remove",
        value: 0,
    },
    existingDripEdge: {
        category: "structures",
        label: "Existing Drip Edge?",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: true,
    },
    numChimneys: {
        category: "structures",
        type: "tel",
        label: "Number of Chimneys",
        value: 2,
    },
    numSkyLights: {
        category: "structures",
        type: "tel",
        label: "Number of Skylights",
        value: 0,
    },
    newVent: {
        category: "structures",
        label: "New Ridge Vent",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: true,
    },
    gutterReset: {
        category: "structures",
        label: "Gutters - Detach/Reset",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    antennas: {
        category: "structures",
        label: "Remove Existing Antennas",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: true,
    },
    addStructures: {
        category: "structures",
        label: "Additional Structures",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: true,
    },
    // RidgesLF: Linear feet of the ridge line
    ridgesLF: {
        category: "dimensions",
        type: "tel",
        label: "Ridges (LF)",
        value: 246.1,
    },
    // HipsLF: Linear feet of the external angle formed by the intersection of two sloping roof planes
    hipsLF: {
        category: "dimensions",
        type: "tel",
        label: "Hips (LF)",
        value: 54,
    },
    // ValleysLF: Linear feet of the internal angel formed by the intersection of two sloping roof planes
    valleysLF: {
        category: "dimensions",
        type: "tel",
        label: "Valleys (LF)",
        value: 163,
    },
    // EavesLF: Linear feet of the roof edge that overhangs the face of the exterior wall
    eavesLF: {
        category: "dimensions",
        type: "tel",
        label: "Eaves (LF)",
        value: 249,
    },
    // RakesLF: Linear feet of the roof edge that overhangs the gable end of the house
    rakesLF: {
        category: "dimensions",
        type: "tel",
        label: "Rakes (LF)",
        value: 247.1,
    },
    ridgeVentLF: {
        category: "dimensions",
        type: "tel",
        label: "Ridge Vent (LF)",
        value: 246.1,
    },
    boxVent750: {
        category: "materials",
        type: "tel",
        label: "Box Vents 750",
        value: 0,
    },
    powerVentCover: {
        category: "structures",
        type: "tel",
        label: "Power Assisted Vents",
        value: 0,
    },
    pipeBoots: {
        category: "materials",
        type: "tel",
        label: "Pipe Boots",
        value: 0,
    },
    // Small/Large pipe boots being converted to available "types" under 'Pipe Boots'
    // {
    //     id: "pipeBootLg",
    //     category: "materials",
    //     label: 'Pipe Boots 1"-4"',
    //     value: 0,
    // },
    leadPipeBoot: {
        category: "materials",
        type: "tel",
        label: "Lead Pipe Boots",
        value: 0,
    },
    electricSplitBoot: {
        category: "materials",
        type: "tel",
        label: "Electric Split Boots",
        value: 0,
    },
    crickets: {
        category: "structures",
        type: "tel",
        label: "Crickets",
        value: 0,
    },
    ccBV: {
        category: "materials",
        type: "tel",
        label: "Cover/Cut in BV",
        value: 0,
    },
    mansard: {
        category: "structures",
        type: "tel",
        label: "Mansard",
        value: 0,
    },
    secondStorySF: {
        category: "dimensions",
        type: "tel",
        label: "2nd Story (SF)",
        value: 3,
    },
    existingVent: {
        category: "structures",
        label: "Existing Ventilation",
        options: {
            boxVent: { label: "Box Vent", value: "boxVent" },
            ridgeVent: { label: "Ridge Vent", value: "ridgeVent" },
        },
        value: "boxVent",
    },
    broanVents: {
        category: "structures",
        type: "tel",
        label: "Broan Vents",
        value: 0,
    },
    atticAC: {
        category: "structures",
        label: "A/C in Attic",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    supplyCaulk: {
        category: "materials",
        label: "Supply Caulking",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    sprayPaint: {
        category: "materials",
        label: "Include Spray Paint",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    eaveExp: {
        category: "dimensions",
        type: "tel",
        label: "Eave Exp (SF)",
        value: 0,
    },
    waste: {
        category: "details",
        type: "tel",
        label: "Waste %",
        value: 15,
    },
    // change perimeter to be Eaves + Rakes
    perimeterLF: {
        category: "dimensions",
        type: "tel",
        label: "Perimeter (LF)",
        value: 496.1,
    },
    // change Squares to be roof area divided by 100
    squares: {
        category: "dimensions",
        label: "Squares",
        value: 0,
    },
    stepFlash: {
        category: "dimensions",
        type: "tel",
        label: "Step Flashing (LF)",
        value: 92,
    },
    wallFlash: {
        category: "dimensions",
        type: "tel",
        label: "Wall Flashing (LF)",
        value: 0,
    },
    counterFlash: {
        category: "materials",
        type: "tel",
        label: "Counter Flashing",
        value: 0,
    },
    replaceSkylight: {
        category: "structures",
        type: "tel",
        label: "Skylight Replacements",
        value: 0,
    },
    flashSkylight: {
        category: "structures",
        type: "tel",
        label: "Re-Flash Skylight",
        value: 0,
    },
    replaceDecking: {
        category: "details",
        type: "tel",
        label: "Decking 1x board replace (LF)",
        value: 0,
    },
    xlDripEdgeArea: {
        category: "dimensions",
        type: "tel",
        label: "XL Drip Edge (Low Slope Area)(SF)",
        value: 0,
    },
    baseCapSheet: {
        //LSA SF: Low Slope Area SF
        id: "baseCapSheet",
        category: "dimensions",
        type: "tel",
        label: "Base/Cap Sheet(LSA SF)",
        value: 0,
    },
    roofPitch: {
        category: "dimensions",
        label: "Predominate Roof Pitch (x/12)",
        value: "4/12",
    },
    decking: {
        category: "materials",
        type: "tel",
        label: "Decking 4x8",
        value: 0,
    },
    customerName: {
        category: "jobDetails",
        label: "Customer Name",
        value: "",
        placeholder: "Customer name",
    },
    email: {
        category: "jobDetails",
        label: "Email",
        value: "",
        type: "email",
        placeholder: "email",
    },
    phone: {
        category: "jobDetails",
        label: "Phone",
        value: "",
        type: "tel",
        placeholder: "phone",
    },
    street: {
        category: "jobDetails",
        label: "Street",
        value: "",
        placeholder: "street",
    },
    city: {
        category: "jobDetails",
        label: "City",
        value: "",
        placeholder: "city",
    },
    state: {
        category: "jobDetails",
        label: "State",
        value: "",
        type: "text",
        placeholder: "state",
    },
    zip: {
        category: "jobDetails",
        label: "Zip",
        value: "",
        type: "tel",
        inputmode: "numeric",
        placeholder: "zip",
    },
    existingRoofMaterial: {
        category: "jobDetails",
        label: "Existing Roof Material",
        options: ["3 Tab", "Laminate", "Metal", "Wood Shake"],
        value: { id: "Laminate", label: "Laminate" },
    },
    supplier: {
        category: "jobDetails",
        label: "Supplier",
        options: [],
        value: {},
    },
    shingleChoice: {
        category: "jobDetails",
        label: "Shingle Choice",
        options: [],
        value: {},
    },
    shingleColor: {
        category: "jobDetails",
        label: "Shingle Color",
        options: [],
        value: { label: "Estate Gray", id: "estate" },
    },
    sub: {
        category: "jobDetails",
        label: "Sub",
        options: [],
        value: {},
    },
    replacementType: {
        category: "jobDetails",
        label: "Replacement Type",
        options: ["Full Replacement", "Partial Replacement"],
        value: { id: "Full Replacement", label: "Full Replacement" },
    },
    leadType: {
        category: "jobDetails",
        label: "Lead Type",
        options: ["Self Originated", "Lead Originated"],
        value: { id: "Self Originated", label: "Self Originated" },
    },
    tentativeInstallDate: {
        category: "jobDetails",
        label: "Tentative Install Date",
        value: "",
        type: "date",
    },
    materialDeliveryDate: {
        category: "jobDetails",
        label: "Material Delivery Date",
        value: "",
        type: "date",
    },
    orderNewDripEdge: {
        category: "details",
        label: "Order New Drip Edge",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: false,
    },
    includeIceAndWaterOnEaves: {
        category: "details",
        label: "Include Ice and Water on Eaves",
        options: {
            true: { label: "Yes", value: true },
            false: { label: "No", value: false },
        },
        value: true,
    },
};

export default inputs;
