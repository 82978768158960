<template>

        <!-- :graphql="`
        query {
            opportunities(
                limit: 10000,
                sort: {created: -1},
                search: {compareCreated: {greaterThan: ${new Date(today)*1}}}
            ) {
                id
                address {
                    coordinates {
                        lat
                        lng
                    }
                    city
                    state
                }
                ownerInfo {
                    firstName
                    lastName
                }
                selfie {
                    url
                }
                title
                status
                revenue
                created
                authorId
                shotgun {
                    id
                }
            }
        }
        `" -->

    <!-- <Mango
        collection="opportunities"
        :query="{
            limit: 100,
            fields: [
                'address.coordinates',
                'address.city',
                'address.state',
                'ownerInfo.firstName',
                'ownerInfo.lastName',
                'selfie',
                'title',
                'status',
                'revenue',
                'created',
                'authorId',
                'shotgun'
            ],
            sort: {created: -1},
            search: search
        }"
        v-slot="{loading}"
        @update:data="opportunities = $event?.opportunities || $event"
    > -->

        <Spinner v-if="loading" />
        <Draggable v-else
            v-model="dashboardSettings.widgets" item-key="name"
            :disabled="store.touchDevice"
            class="w-full grid grid-flow-row-dense gap-8 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 p-4 md:p-8 "
        >
            <template #item="{element}">
                <Component :is="element.component" :opportunities="activeOpportunities" :finishedLoading="finishedLoading" :startRange="startRange" />
            </template>
        </Draggable>

    <!-- </Mango> -->

</template>

<script>
import latestOpportunities from '../../widgets/latestOpportunities.vue'
import topPerformers from '../../widgets/topPerformers.vue'

import Draggable from 'vuedraggable'
import dayjs from 'dayjs'
import Mango from '../../helpers/mango'
import latestCutoff from '../../helpers/latestCutoff'

export default {
    inject: ['store'],
    components: {
        Draggable,

        latestOpportunities,
        topPerformers
    },
    data() {
        return {
            page: 0,
            lastMonth: dayjs().subtract(1, 'month').toDate(),
            today: dayjs(dayjs().format('YYYY-MM-DD')),
            opportunities: [],
            loading: true,
            finishedLoading: false,
            fetchOpportunitiesInterval: null,
            fields: [
                'address.formatted',
                'address.coordinates',
                'address.city',
                'address.state',
                'ownerInfo.firstName',
                'ownerInfo.lastName',
                'selfie',
                'title',
                'status',
                'revenue',
                'created',
                'updated',
                'authorId',
                'shotgun',
                'branch',
                'ktwc',
                'timestamps',
                'insuranceAccepted',
                'contractSigned',
                'inspection',
                'discussion',
                'revenueReported',
            ],
            dashboardSettings: {
                widgets: [
                    {name: 'Latest Opportunities', component: 'latestOpportunities'},
                    {name: 'Top Performers', component: 'topPerformers'},
                ]
            }
        }
    },
    created() {
        let savedSettings = window.localStorage.getItem('dashboardSettings')
        if (savedSettings) {
            savedSettings = JSON.parse(savedSettings)
            let missingWidgets = this.dashboardSettings.widgets.filter(w => !savedSettings.widgets.find(s => s.name == w.name))

            this.dashboardSettings = savedSettings
            this.dashboardSettings.widgets.push(...missingWidgets)
        }
    },
    mounted() {
        this.getMoreOpportunities()
        // let intervalMinutes = 5
        let intervalMinutes = 0.1
        if (process.env.NODE_ENV == 'production') intervalMinutes = 0.5
        this.fetchOpportunitiesInterval = setInterval(() => {
            this.getMoreOpportunities()
        }, 1000 * 60 * intervalMinutes)
    },
    beforeRouteLeave() {
        clearInterval(this.fetchOpportunitiesInterval)
    },
    watch: {
        dashboardSettings: {
            handler() {
                window.localStorage.setItem('dashboardSettings', JSON.stringify(this.dashboardSettings))
            },
            deep: true
        }
    },
    computed: {
        startRange() {
            return this.latestCutoff()
        },
        search() {

            console.log('startRange', this.startRange)

            let search = {
                // compareUpdated: {
                //     greaterThan: this.startRange
                // }
                $or: [
                    { "timestamps.knock": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.talk": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.walk": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.contingency": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.revenue": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.softSet": { $gt: this.startRange, $lt: this.endRange } },
                    { "timestamps.estimate": { $gt: this.startRange, $lt: this.endRange } },
                    { insuranceAccepted: { $gt: this.startRange, $lt: this.endRange } },
                    { contractSigned: { $gt: this.startRange, $lt: this.endRange } },
                    { created: { $gt: this.startRange, $lt: this.endRange } },
                ],
            }

            if (this.store?.user?.level <= 3) {
                search.branch = {$in: this.store.user?.branches?.map(b => b.id)}
            }

            return search
        },
        limit() {
            return this.finishedLoading ? 75 : 1000
        },
        activeOpportunities() {

            return this.opportunities

            function getProgressionValue(opportunity) {
                if (opportunity.ktwc?.contingency === 1) return 4;
                if (opportunity.ktwc?.walk === 1) return 3;
                if (opportunity.ktwc?.talk === 1) return 2;
                if (opportunity.ktwc?.knock === 1) return 1;
                return 0; // Default to 1 for 'knock' or if none of the stages are set
            }

            // Sort by progression (descending) and then by address
            let ops = [...this.opportunities]
            ops.sort((a, b) => {
                const progressionA = getProgressionValue(a);
                const progressionB = getProgressionValue(b);
                if (progressionA !== progressionB) {
                    return progressionB - progressionA; // Descending order of progression
                }
            });

            // Remove duplicates based on address.formatted, keeping the one furthest in the ktwc progression
            ops = ops.filter((l, i, a) => a.findIndex(t => (t.address?.formatted || '').toLowerCase() === (l.address?.formatted || '').toLowerCase()) === i);

            let consideredFields = [
                'knock',
                'talk',
                'walk',
                'contingency',
                'insuranceAccepted',
                'contractSigned',
                'revenue',
                'softSet',
                'created',
                'estimate',
            ]

            return ops.filter(o => consideredFields.some(f => this.inRange(o, f)))?.sort((a, b) => new Date(b.updated) - new Date(a.updated))
        }
    },
    methods: {
        latestCutoff,
        inRange(l, param) {
            param = l?.timestamps?.[param] || l?.[param]
            return new Date(param) > new Date(this.startRange)
        },
        async getMoreOpportunities() {

            if (this.store.opportunities?.length) {
                let newOpportunities = this.store.opportunities.filter(o => !this.opportunities.find(n => n.id == o.id))
                this.opportunities.push(...newOpportunities)
                this.loading = false
            }

            let opportunities = await Mango.opportunities({depthLimit: 0, limit: this.limit, page: this.page, search: this.search, fields: this.fields, sort: {updated: -1}})
            // let opportunities = (await Mango.graphql(`
            // query {
            //     opportunities(
            //         limit: ${this.limit},
            //         page: ${this.page},
            //         search: {compareUpdated: {greaterThan: ${new Date(this.startRange)*1}}},
            //         sort: {updated: -1}
            //     ) {
            //         id
            //         address {
            //             formatted
            //             coordinates {
            //                 lat
            //                 lng
            //             }
            //             city
            //             state
            //         }
            //         ownerInfo {
            //             firstName
            //             lastName
            //         }
            //         selfie {
            //             url
            //         }
            //         title
            //         status
            //         revenue
            //         created
            //         updated
            //         authorId
            //         shotgun {
            //             id
            //         }
            //         branch {
            //             id
            //             entityType
            //         }
            //         ktwc {
            //             knock
            //             talk
            //             walk
            //             contingency
            //         }
            //         timestamps {
            //             knock
            //             talk
            //             walk
            //             contingency
            //             revenue
            //             softSet
            //             estimate
            //         }
            //         insuranceAccepted
            //         contractSigned
            //         inspection
            //         discussion
            //     }
            // }
            // `))?.opportunities

            // If they're not authorized, log them out
            if (typeof opportunities == 'string' && opportunities?.includes?.('not have permission')) {
                this.store.user = {}
                window.localStorage.removeItem('user')
                window.localStorage.removeItem('token')
                window.localStorage.removeItem('email')
                window.localStorage.removeItem('auth')
                setCookie(`Authorization`, ``)
                window.location.reload()
            }

            console.log('opportunities', opportunities)

            let newOpportunities = opportunities.filter(o => !this.opportunities.find(n => n.id == o.id))
            if (newOpportunities?.length) {
                if (this.finishedLoading) this.opportunities.unshift(...newOpportunities)
                else this.opportunities.push(...newOpportunities)
                this.loading = false
            }

            if (this.finishedLoading ? (newOpportunities.length == this.limit) : (opportunities.length == this.limit)) {
                this.page += 1
                this.getMoreOpportunities()
            } else {
                console.log('finished loading')
                this.loading = false
                this.finishedLoading = true
                this.page = 0
            }
        }
    }
}
</script>
