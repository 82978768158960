<template>

    <!-- Top Nav Bar -->
    <div
        @scroll="handleScroll" v-show="!hidden"
        class="
            space-x-8 sticky backdrop-blur left-0 print:hidden
            text-red-500 border-b border-gray-200 bg-white/90 flex justify-between items-center px-4 top-0 w-full h-14 z-50
            dark:text-red-100 dark:border-gray-600 dark:bg-gray-800/50
        "
    >
        <div class="flex items-center space-x-4">
            <button @click.stop="store.showMenu = !store.showMenu"><i class="md:hidden fal fa-bars text-red-700 dark:text-red-50 text-lg" /></button>
            <router-link class="w-48" to="/">
                <img src="/images/logo.jpg" alt="Logo" class="w-full" />
            </router-link>
        </div>
        <!-- <router-link class="hidden sm:block" to="/">Best Choice Roofing</router-link> -->
        <div class="w-48 flex justify-end items-center space-x-2">
            <!-- <button @click="darkMode.toggle" class="text-xl"><i class="fad" :class="!darkMode.enabled ? 'fa-sun' : 'fa-moon-stars mb-2'"></i></button> -->
            <!-- <button @click="showSearch = true"><i class="fal fa-search text-red-700 dark:text-red-50" /></button> -->
            <div
                v-if="role"
                class="hidden sm:block rounded px-2 py-1 text-2xs bg-gray-100 text-white font-semibold"
                :class="{
                    'bg-red-500': role == 'admin',
                    'bg-purple-500': ([2,3].includes(store.user?.level)),
                    'bg-gray-500': store.user?.level == 1,
                }"
            >
                {{ role }}
            </div>
            <button @click="store.user?.id ? $router.push('/account') : showLogin = true"><i :class="store.user?.id ? 'fas' : 'fal'" class="fa-user-circle text-red-700 dark:text-red-50 text-xl" /></button>
        </div>
    </div>


    <!-- <Login @hide="hideLogin" @loggedIn="loggedIn" v-show="!store.user?.id && !$route?.name?.includes('playground')" :allowGuest="false" ref="login" class="max-w-lg mx-auto" /> -->
    <Login v-show="false" ref="login" class="max-w-lg mx-auto" />

    <Modal v-if="showSearch" @hide="showSearch = false">
        <div class="text-sm sm:text-base md:text-lg flex justify-between w-full items-start">
            <div>Search for Anything!</div>
            <button @click="showSearch = false">
                <i class="fal fa-times ml-4" />
            </button>
        </div>
        <input type="text" v-model="search.value" class="w-full text-sm sm:text-base md:text-lg" placeholder="Search" ref="search">
        <!-- Something is breaking and keeping the slot content from showing up! -->
        <Mango
            :algoliaSearch="search.value"
            :algoliaFilters="`collection:events OR collection:resources OR collection:podcasts OR collection:products OR collection:blogs`"
            :globalSearch="true"
            @update:data="test"
            @update:loading="search.loading = $event"
        />
        <div v-if="search.loading && search.value" class="flex items-center justify-center"><Spinner :small="true" class="w-8 h-8 mt-2" /></div>
        <div v-else-if="search.value" class="space-y-2 sm:space-y-4">
            <router-link
                :to="`/${entry.collection}/${entry.id}`"
                v-for="entry in search.results"
                :key="entry.id"
                @click="showSearch = false"
                class="p-2 sm:p-4 dark:bg-gray-700 rounded shadow-card flex flex-col group"
            >
                <div class="flex items-center">
                    <div class="hidden sm:flex mr-4 rounded-full w-8 h-8 shrink-0 border border-white dark:border-gray-400 bg-gray-800 uppercase text-white/60 text-sm relative overflow-hidden items-center justify-center">
                        <div v-if="entry.title">{{ entry?.title?.[0] }}</div>
                        <div v-else><i class="fa fa-question" /></div>
                        <div class="bg-cover bg-center w-full h-full absolute inset-0" :style="`background-image: url('${entry?.image?.url}')`" />
                    </div>
                    <div class="truncate">
                        <div
                            class="text-2xs uppercase font-semibold"
                            :class="collectionColors[entry.collection] || 'text-red-500'"
                        >
                            {{ entry.collection }}
                        </div>
                        <div class="text-sm group-hover:underline truncate">{{ entry.title }}</div>
                    </div>
                </div>
            </router-link>
        </div>
    </Modal>
</template>

<script>
import logo from '../../assets/logo.vue'
import Login from '../layout/login.vue'
// import Subscribe from './subscribe.vue';

export default {
    components: { logo, Login },
    props: {hidden: {type: Boolean, default: false}},
    inject: ['store', 'darkMode', 'collections'],
    data() {
        return {
            showLogin: false,
            showSearch: false,
            keyListener: null,
            search: {
                value: null,
                results: [],
                loading: false
            },
            showMenu: false,
            collectionColors: {
                events: 'text-ncfic-green-500',
                products: 'text-red-500',
            }
        }
    },
    mounted() {
        // this.keyListener = function(e) {
        //     if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        //         e.preventDefault(); // present "Save Page" from getting triggered.
        //         this.showSearch = !this.showSearch
        //     }
        // };
        // document.addEventListener('keydown', this.keyListener.bind(this));
    },
    beforeDestroy() {
        // document.removeEventListener('keydown', this.keyListener);
    },
    watch: {
        showSearch() {
            if (this.showSearch) this.$nextTick(() => this.$refs.search.focus())
        }
    },
    methods: {
        test(data) {
            this.search.results = data
        },
        hideLogin() {
            this.showLogin = false
            if (this.store.login?.next) this.store.login.next = null
        },
        loggedIn() {
            if (this.store.login?.next) this.$router.push(this.store.login.next)
        }
    },
    computed: {
        role() {
            return Array.isArray(this.store?.user?.roles) ? this.store?.user?.roles?.[0] : this.store?.user?.roles
        }
    }
}
</script>

<style lang="postcss" scoped>
input, label {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 focus-visible:dark:outline-blue-500/40 px-3 py-2 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}
input.dark-sidebar {
    @apply dark:border-white/70 dark:placeholder:text-white/30 dark:text-white
}
</style>
