<template>
    <button
        @click="value = !Boolean(value)"
        class="flex justify-center items-center rounded-l-full rounded-r-full transition-all outline-none"
        :class="[!value ? background : 'bg-green-500 dark:bg-green-800', small ? 'w-[30px] p-[2px]' : 'w-14 p-1']"
    >
        <div
            class="bg-white dark:bg-gray-400 shrink-8 rounded-full transition-all"
            :class="[!value ? '-translate-x-1/2' : 'translate-x-1/2', small ? 'w-[13px] h-[13px]' : 'w-6 h-6']"
        />
    </button>
</template>

<script>
import {useModel} from '../../helpers/model'

export default {
    props: {
        modelValue: {type: Boolean},
        background: {default: 'bg-gray-100 dark:bg-gray-800'},
        small: {default: false}
    },
    watch: {
        value(n,o) {
            if (n != o) this.$emit('change', this.value)
        }
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>
