<template>
    <div class="w-full group flex flex-col relative">

        <!-- <div v-if="modelSelectedEntries" class="space-y-2 mb-2">
            <div v-for="entry in modelSelectedEntries" :key="entry.id" class="flex items-center space-x-4 border dark:border-gray-500 p-2 md:p-4 rounded-lg relative overflow-hidden">
                <div
                    v-if="image"
                    class="rounded-full shrink-0 w-6 h-6 md:w-8 md:h-8 bg-gray-800 dark:bg-gray-700 uppercase text-white relative flex items-center justify-center bg-cover bg-center"
                    :style="`background-image: url(${entry?.image?.url})`"
                    v-html="entry?.image?.url ? '' : entry.title[0]"
                />
                <div class="">{{ entry.title }}</div>
                <button @click="remove(entry.id)" class="flex opacity-0 hover:opacity-100 bg-gray-500/75 backdrop-blur-[1px] absolute top-0 left-0 !m-0 items-center justify-center h-full w-full">
                    <i class="far fa-trash-alt text-white text-lg" />
                </button>
            </div>
        </div> -->

        <Draggable v-model="selectedEntries" v-show="selectedEntries?.length" item-key="id" class="divide-y dark:divide-gray-800 overflow-scroll max-h-[25vh] w-full" :class="{'mb-2': multiple}" >
            <template #item="{element}">
                <div class="space-y-2">
                    <div class="flex items-center space-x-4 p-2 relative w-full hover:bg-gray-100 dark:hover:bg-gray-700">
                        <div v-if="multiple" class="cursor-pointer"><i class="fal fa-grip-lines" /></div>
                        <div
                            v-if="image"
                            class="rounded-full shrink-0 w-6 h-6 md:w-8 md:h-8 bg-gray-800 dark:bg-gray-700 uppercase text-white relative flex items-center justify-center bg-cover bg-center"
                            :style="`background-image: url(${element?.image?.url || element?.selfie?.url})`"
                            v-html="element?.image?.url || element?.selfie?.url ? '' : element?.title?.[0]"
                        />
                        <div class="flex-grow">{{ element?.title }}</div>
                        <button @click="remove(element?.id)" class="sm:hidden group-hover:block">
                            <i class="fal fa-trash-alt text-red-500 text-lg" />
                        </button>
                    </div>
                </div>
            </template>
        </Draggable>

        <button class="text-sm text-gray-500 px-3 py-2 border-2 dark:border-gray-700 rounded" @click="enableSearch" v-show="!add && (!selectedEntries?.length || multiple)">Add {{ singular }}</button>
        <input
            v-show="add && (!selectedEntries?.length || multiple)"
            ref="input"
            type="text" :placeholder="placeholder || `Search`"
            v-model="search" @keydown.enter="select(targetId)"
            @keydown.up="previous" @keydown.down="next"
            class="w-full bg-transparent"
            :class="inputStyle"
        />

        <div class="relative hidden group-focus-within:block">
            <div class="absolute top-0 left-0 w-full z-50">
                <Mango
                    v-if="search"
                    :algoliaSearch="search"
                    :algoliaFilters="algoliaFilters"
                    :query="{fields:['id','title','image','address','speakers']}"
                    :collection="collection" :id="null"
                    v-slot="{loading}"
                    @update:data="hits = $event"
                    :disabled="!!facet"
                    class="max-w-xs bg-white dark:bg-gray-800 z-10
                    w-full rounded-lg shadow-card "
                >
                    <div v-if="loading || loadingFacets" class="w-full flex items-center justify-center"><Spinner :small="true" class="w-8 h-8 m-8" /></div>
                    <div v-else-if="create || hits.length" class="divide-y dark:divide-gray-500 overflow-scroll max-h-60 border-2 dark:border-gray-500 rounded-lg" ref="scrollContainer">
                        <div
                            tabindex="0"
                            v-if="create"
                            class="flex items-center p-4 space-x-4 w-full hover:bg-green-50"
                            @click.stop="select('new')"
                        >
                            <div class="rounded-full shrink-0 w-8 h-8 bg-gray-800 flex items-center justify-center bg-green-500">
                                <i class="fa fa-plus text-white" />
                            </div>
                            <div class="">Add New</div>
                        </div>
                        <div
                            tabindex="0"
                            v-for="entry in hits"
                            :key="entry.id"
                            class="flex items-center p-4 space-x-4 w-full"
                            @mouseenter="targetId = entry.id"
                            :class="{'bg-blue-50 dark:bg-gray-900': targetId == entry.id, 'bg-green-50 dark:bg-green-600/40': selectedIds.includes(entry.id)}"
                            :ref="setItemRef"
                            @click.stop="select(entry.id)"
                        >
                            <div v-if="selectedIds.includes(entry?.id)" class="rounded-full shrink-0 w-8 h-8 bg-gray-800 flex items-center justify-center bg-green-500">
                                <i class="fa fa-check text-white" />
                            </div>
                            <div
                                v-else-if="image"
                                class="rounded-full shrink-0 w-8 h-8 bg-gray-700 uppercase text-white relative flex items-center justify-center bg-cover bg-center"
                                :style="`background-image: url(${entry?.image?.url})`"
                                v-html="entry?.image?.url ? '' : entry?.title?.[0]"
                            />
                            <div class="text-left truncate">
                                <div class="truncate">{{ entry?.title }}</div>
                                <div class="text-sm self-start text-gray-500">
                                    <template v-if="collection == 'churches'">{{ entry?.address?.city }}, {{ entry?.address?.state }}</template>
                                    <template v-if="collection == 'sermons'">{{ entry?.speakers?.map(s => s?.title)?.join(',') }}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                </Mango>
            </div>
        </div>

    </div>
</template>

<script>
import { algoliaAppId, algoliaSearchKey, algoliaIndex } from '../../../config/config/settings.json'
import collections from '../../../config/config/.collections.json'
import algoliasearch from 'algoliasearch/lite'
import Draggable from 'vuedraggable'

const client = algoliasearch(algoliaAppId, algoliaSearchKey);
const algolia = client.initIndex(algoliaIndex);

import { useModel } from '../helpers/model'
export default {
    components: {Draggable},
    props: {
        multiple: {type: Boolean, default: true},
        image: {type: Boolean, default: true},
        create: {type: Boolean, default: false},
        collection: {},
        placeholder: {},
        facet: {},
        modelSelectedIds: {default: []},
        modelSelectedEntries: {default: []},
        algoliaFilters: {default: null},
        inputStyle: {default: null},
    },
	setup(props, { emit }) {
		return {
            selectedIds: useModel(props, emit, 'modelSelectedIds'),
			selectedEntries: useModel(props, emit, 'modelSelectedEntries')
		}
	},
    data() {
        return {
            search: null,
            targetId: null,
            hits: [],
            itemRefs:[],
            // selectedIds: [],
            // selectedEntries: [],
            loadingFacets: false,
            add: false,
            singular: collections.find(c => c.name == this.collection)?.singular
        }
    },
    watch: {
        search() {
            console.log('search changed')
            this.targetId = null
            if (this.facet) this.facetSearch()
        },
        hits() {
            if (this.hits?.length) this.targetId = this.hits?.[0]?.id
        }
    },
    methods: {
        enableSearch() {
            this.add = true
            this.$nextTick(() => this.$refs.input.focus())
        },
        async facetSearch() {
            this.loadingFacets = true
            let {facetHits} = await algolia.searchForFacetValues(this.facet, this.search)
            this.loadingFacets = false
            console.log('facetHits', facetHits)
            this.hits = facetHits.map((hit, index) => ({title: hit.value, id: index}))
            console.log('this.hits', this.hits)
        },
        setItemRef(el) {
            if (el) this.itemRefs.push(el)
        },
        select(id) {
            this.$emit('selected', id)

            this.selectedIds.push(id)
            this.$emit('selectedIds', this.selectedIds)
            this.$emit('update:selectedIds', this.selectedIds)

            let selectedEntry = id == 'new' ? {id, title: this.search} : this.hits.find(h => h.id == id)
            this.selectedEntries.push(selectedEntry)
            this.$emit('selectedEntries', this.selectedEntries)
            this.$emit('update:selectedEntries', this.selectedEntries)

            this.search = null
            this.targetId = null
            this.add = false
        },
        remove(id) {
            let idIndex = this.selectedIds.findIndex(i => i == id)
            this.selectedIds.splice(idIndex, 1)

            let selectedEntryIndex = this.selectedEntries.findIndex(h => h.id == id)
            this.selectedEntries.splice(selectedEntryIndex, 1)

            this.$emit('removed', id)
            this.$emit('selectedIds', this.selectedIds)
            this.$emit('selectedEntries', this.selectedEntries)
            this.$emit('update:selectedIds', this.selectedIds)
            this.$emit('update:selectedEntries', this.selectedEntries)

            this.$forceUpdate()
        },
        next() {
            if (this.targetIndex < 0 && this.hits.length) this.targetId = 0
            if (this.targetIndex+1 == this.hits.length) return
            this.targetId = this.hits[this.targetIndex+1].id
            let offsetTop = this.itemRefs[this.targetIndex].offsetTop
            console.log('offsetTop', offsetTop)
            this.$refs.scrollContainer.scrollTop = offsetTop
        },
        previous() {
            if (!this.targetIndex) return
            this.targetId = this.hits[this.targetIndex-1].id
            let offsetTop = this.itemRefs[this.targetIndex].offsetTop
            console.log('offsetTop', offsetTop)
            this.$refs.scrollContainer.scrollTop = offsetTop
        }
    },
    computed: {
        targetIndex() { return this.hits.findIndex(h => this.targetId == h.id)},
    },
    beforeUpdate() {
        this.itemRefs = []
    },
}
</script>

<style lang="postcss" scoped>
input {
    @apply border rounded outline-blue-400 px-3 py-2 w-full dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-500
}
</style>
