<template>
    <div class="w-full p-8 md:p-16">

        <Mango @update:data="branch = $event" collection="branches">
            <div v-if="branch?.id" class="flex flex-col items-center">

                <h2 class="text-center text-2xl">{{ branch?.title }}</h2>
                <!-- <h3 class="text-center opacity-50 mb-8 md:mb-16">{{ branch?.address?.city }}, {{ branch?.address?.state }}</h3> -->
                <div class="flex space-x-4">
                    <button v-if="members?.length" @click="downloadVcard" class="underline opacity-50 mb-8 md:mb-16">Download Contacts</button>
                    <!-- <Spinner v-if="downloadingContacts" :small="true" class="w-4 h-4" /> -->
                </div>

                <Mango
                    @update:data="members = $event"
                    v-slot="{loading}"
                    collection="members"
                    :id="null"
                    :query="{search: {branches: branch.id, status: {$in: ['open','invited']}}, limit: 10000}"
                    class="space-y-4 md:space-y-8 w-full max-w-3xl mx-auto"
                >
                    <Spinner :small="true" class="w-16 h-16 mx-auto mt-16" v-if="loading" />
                    <div v-for="level, role in roles" :key="role">

                        <template v-if="level <= store.user.level && level <= 3">

                            <div class="uppercase text-xs font-semibold tracking-widest mb-2 md:mb-4 flex w-full justify-between items-end">
                                <div class="opacity-50">{{ role?.replace(/([A-Z])/g, ' $1')?.toTitleCase() }}s</div>
                                <button v-if="level <= 3" class="px-2 py-1 rounded" :class="role == 'salesRep' ? 'bg-blue-500/80 text-white' : 'border'" @click="inviteRole = role">Invite +</button>
                            </div>

                            <div class="divide-y border rounded-lg overflow-hidden">
                                <router-link
                                    :to="`/members/${member.id}/report`"
                                    v-for="member in members.filter(m => m.roles?.includes(role))" :key="member.id"
                                    class="p-2 md:p-4 flex items-center space-x-4 hover:bg-gray-100 transition-all group"
                                    :class="{'dark:bg-yellow-100/5 bg-yellow-300/10 hover:!bg-yellow-300/20': member.status == 'invited'}"
                                >
                                    <div v-if="member.status == 'invited'" class="overflow-hidden w-full flex justify-between items-center">
                                        <div class="px-2 truncate">
                                            <div class="truncate text-sm sm:text-base">{{ member.email }}</div>
                                            <div class="text-xs opacity-50 mt-2 truncate">This member has been invited, but has not yet entered their data.</div>
                                        </div>
                                        <div class="flex-grow text-right w-20 md:hidden group-hover:md:block">
                                            <button @click.stop.prevent="resendInvite(member)" v-tooltip="`Resend welcome email.`" class="hover:text-black text-gray-500 mr-4"><i class="fa fa-envelope" /></button>
                                            <button
                                                @click.stop.prevent="deleteMember(member)"
                                                v-tooltip="`Remove Invite`"
                                                class="text-red-500 dark:text-red-700 hover:text-red-600 dark:hover:text-red-500"
                                            >
                                                <i class="fa fa-trash-alt" />
                                            </button>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <People :people="[member]" class="group-hover:scale-110 transition-all shrink-0" />
                                        <div>
                                            <div class="group-hover:underline text-sm md:text-base">{{ member?.title }}</div>
                                            <div class="text-xs md:text-sm opacity-50 truncate hidden sm:block">{{ member?.email }}</div>
                                            <button @click.stop.prevent="addContact(member)" class="underline text-xs sm:hidden">Add to Contacts</button>
                                        </div>
                                        <div class="flex-grow flex justify-end space-x-2 sm:hidden group-hover:flex">
                                            <button @click.stop.prevent="addContact(member)" class="underline hidden sm:block hover:text-black text-gray-500" v-tooltip="`Add to Contacts`"><i class="fa fa-user" /></button>
                                            <button @click.stop.prevent="resendInvite(member)" v-tooltip="`Resend welcome email.`" class="hover:text-black text-gray-500 mr-4"><i class="fa fa-envelope" /></button>
                                            <router-link :to="`/members/${member.id}`" class="" v-tooltip="`Edit Member`">
                                                <i class="fa fa-pencil text-blue-500" />
                                            </router-link>
                                            <button
                                                @click.stop.prevent="removeMember(member)"
                                                v-tooltip="`Remove From Branch`"
                                                class="text-red-500 dark:text-red-700 hover:text-red-600 dark:hover:text-red-500 mr-4"
                                            >
                                                <i class="fa fa-trash-alt" />
                                            </button>
                                        </div>
                                    </template>
                                </router-link>
                            </div>

                        </template>

                    </div>
                </Mango>
            </div>

            <SendInvites v-if="inviteRole" :branch="branch" :role="inviteRole" @hide="inviteRole = null" @added="members.push($event)" />

        </Mango>

    </div>
</template>

<script>
import People from '../components/layout/people.vue'
import createVcard from '../helpers/createVcard';
import SendInvites from '../helpers/sendInvites.vue';
import roles from '../../../config/helpers/roles'
import Mango from '../helpers/mango';
import Swal from 'sweetalert2';
import Search from '../components/mapbox/search.vue';
import ReportInfo from '../components/layout/report-info.vue';
import ReportTableRow from '../components/layout/report-table-row.vue';

export default {
	components: { People, SendInvites, Search, ReportInfo, ReportTableRow },
    inject: ['store','axios'],
    data() {
        return {
            branch: {},
            branches: [],
            members: [],
            inviteRole: null,
            downloadingContacts: false,
            roles,
        }
    },
    methods: {
        async removeMember(member) {

            let approved = (await Swal.fire({
                title: `Are You Sure?`,
                confirmButtonText: 'Remove',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                icon: 'warning',
                html: `Do you really want to remove <em>${member?.title}</em> from the ${this.branch?.title} branch?`
            })).isConfirmed
            if (!approved) return

            let response = await Mango.members.save({id: member.id, branches: member.branches.filter(b => b.id != this.branch.id)})
            if (response.id) {
                this.members = this.members.filter(m => m.id != member.id)
            } else {
                Swal.fire({title: 'Error', text: 'There was an error deleting this member.', icon: 'error'})
            }
        },
        async deleteMember(member) {

            let approved = (await Swal.fire({
                title: `Are You Sure?`,
                confirmButtonText: 'Remove',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                icon: 'warning',
                html: `Do you really want to remove <em>${member.email}</em>?`
            })).isConfirmed
            if (!approved) return

            let response = await Mango.members.delete(member.id)
            if (response.deleted) {
                this.members = this.members.filter(m => m.id != member.id)
            } else {
                Swal.fire({title: 'Error', text: 'There was an error deleting this member.', icon: 'error'})
            }
        },
        async addContact(member) {

            let image = document.createElement('img');

            if (member.image?.url) {
                // Wrap the image load event in a Promise
                let imageLoadPromise = new Promise((resolve, reject) => {
                    image.onload = () => resolve();  // Resolve when image loads
                    image.onerror = () => reject(new Error("Failed to load image")); // Reject on any error
                });

                image.src = `/s3/${member.image?.url?.split('.com/')[1]}`
                await imageLoadPromise;
            }

            let vCardData = await createVcard({ member, image })

            const fileName = `${member.firstName}-${member.lastName}.vcf`;

            let vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
            let vCardUrl = URL.createObjectURL(vCardBlob);
            // return URL.createObjectURL(vCardBlob);

            let link = document.createElement('a');
            link.href = vCardUrl;
            link.download = fileName;
            link.textContent = 'Download vCard';
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);

        },
        async downloadVcard() {

            this.downloadingContacts = true
            let vCardData = ''
            for (let member of this.members) {

                let image = document.createElement('img');

                if (member.image?.url) {
                    // Wrap the image load event in a Promise
                    let imageLoadPromise = new Promise((resolve, reject) => {
                        image.onload = () => resolve();  // Resolve when image loads
                        image.onerror = () => reject(new Error("Failed to load image")); // Reject on any error
                    });

                    image.src = `/s3/${member.image?.url?.split('.com/')[1]}`
                    await imageLoadPromise;
                }

                let vcard = await createVcard({ member, image })
                if (vcard) vCardData += vcard
            }

            const fileName = `bcr-team.vcf`;

            let vCardBlob = new Blob([vCardData], { type: 'text/vcard' });
            let vCardUrl = URL.createObjectURL(vCardBlob);
            // return URL.createObjectURL(vCardBlob);

            let link = document.createElement('a');
            link.href = vCardUrl;
            link.download = fileName;
            link.textContent = 'Download vCard';
            document.body.appendChild(link);
            link.click()
            document.body.removeChild(link);
            this.downloadingContacts = false

        },
        async resendInvite(member) {
            let response = await this.axios.post(`${this.store.api}/controllers/account/sendWelcomeEmail`, {email: member.email})
            if (response.data?.success) Swal.fire({title: 'Success', text: 'Welcome email sent!', icon: 'success'})
            else Swal.fire({title: 'Error', text: 'There was an error sending the welcome email.', icon: 'error'})
        }
    },
    computed: {
        admin() {
            return this.store?.user?.roles?.includes('admin')
        }
    }
}
</script>

<style>

</style>
