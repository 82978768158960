import streetAbbreviations from './streetAbbreviations.json'
import stateAbbreviations from './stateAbbreviations.json'

const directionAbbreviations = {
    "North": "N",
    "South": "S",
    "East": "E",
    "West": "W",
    "Northeast": "NE",
    "Northwest": "NW",
    "Southeast": "SE",
    "Southwest": "SW"
};

const unitAbbreviations = {
    "Apartment": "Apt",
    "Suite": "Ste",
    "Unit": "Unit",
    "Building": "Bldg",
    "Floor": "Fl"
};

const normalizeAddress = (address) => {

    const normalize = (str, dictionary) => {
        for (let [key, value] of Object.entries(dictionary)) {
            let regex = new RegExp(`\\b${key}\\b`, 'gi');
            str = str?.replace?.(regex, value);
        }
        return str;
    };

    let street = normalize(address.street, streetAbbreviations)
    street = normalize(street, directionAbbreviations)
    street = normalize(street, unitAbbreviations)
    let state = (address?.state || address?.stateCode)?.toLowerCase?.()
    state = normalize(state, stateAbbreviations)?.toUpperCase?.()

    let normalizedAddress = {
        ...address,
        street,
        state,
    };

    // Remove punctuation and excess whitespace
    for (let key in normalizedAddress) {
        if (normalizedAddress.hasOwnProperty(key) && typeof normalizedAddress[key] === 'string') {
            normalizedAddress[key] = normalizedAddress[key].replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, ' ').trim();
        }
    }

    normalizedAddress.address = `${normalizedAddress.number} ${normalizedAddress.street}`;
    normalizedAddress.formatted = `${normalizedAddress.number} ${normalizedAddress.street} ${normalizedAddress.city}, ${normalizedAddress.state}`;
    normalizedAddress.formatted = normalizedAddress.formatted.toLowerCase().replace(/\s+/g, ' ').trim();
    normalizedAddress.bcrid = normalizedAddress.formatted?.toLowerCase()?.trim()?.replace(/[^a-zA-Z0-9\s]/g, '')?.replace(/\s/g, '-')

    return normalizedAddress;

};

export default normalizeAddress
