<template>
    <div>
        <Draggable v-model="selections" v-show="selections?.length" item-key="0" class="flex flex-wrap">
            <template #item="{element}">
                <button
                    @click="remove(element)"
                    class="rounded text-white px-3 py-2 mr-2 mb-2 group hover:bg-gray-400 relative text-left"
                    :class="pillBackground"
                >
                    <span class="group-hover:text-gray-400">{{ element }}</span>
                    <div class="group-hover:flex hidden absolute inset-0 items-center justify-center h-full w-full">
                        <i class="far fa-trash-alt" />
                    </div>
                </button>
            </template>
        </Draggable>
        <div>
            <slot :add="add">
                <input type="text" @change="add" v-model="value" :placeholder="placeholder" class="text-base">
            </slot>
        </div>
    </div>
</template>

<script>
import { useModel } from '../helpers/model'
import Draggable from 'vuedraggable'

export default {
    components: {Draggable},
    props: {
        modelValue: {default: []},
        placeholder: {type: String, default: 'Add a selection'},
        pillBackground: {type: String, default: 'bg-gray-500'},
        delimiters: {type: Array},
    },
    data() {
        return {
            value: null
        }
    },
	setup(props, { emit }) {
		return {
            selections: useModel(props, emit, 'modelValue'),
		}
	},
    watch: {
        selections: {
            handler() { this.$emit('selections', this.selections) },
            deep: true
        }
    },
    methods: {
        add(event) {
            console.log('event', event)
            let value = [this.value || event?.target?.value || event]
            if (event?.target?.value) event.target.value = null
            if (this.delimiters) {
                for (let delimiter of this.delimiters) value = value.flatMap(v => v.split(delimiter))
            }
            this.selections = [...new Set([...this.selections, ...value])]
            this.value = null
        },
        remove(element) {
            let index = this.selections?.findIndex(s => s == element)
            this.selections.splice(index, 1)
        }
    }
}
</script>

<style lang="postcss" scoped>
input {
    @apply border rounded outline-blue-400 px-3 py-2 w-full dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}
</style>
