<template>
    <div class="p-4 border dark:border-gray-700 rounded-lg space-y-4 hover:bg-white dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800/50 group">
        <div class="w-full flex justify-between">
            <button><i class="fa fa-grip-lines" /></button>
            <div class="flex space-x-4">
                <button @click="$emit('remove')" class="w-[0.9rem] h-[0.9rem] rounded-full border-2 border-black/50 bg-red-500/50 flex items-center justify-center text-4xs font-bold"><span class="hidden group-hover:inline"><i class="fa fa-times opacity-50"/></span><span class="group-hover:hidden"><i class="fa fa-circle text-5xs"/></span></button>
                <button @click="expanded = !expanded" class="w-[0.9rem] h-[0.9rem] rounded-full border-2 border-black/50 bg-orange-500/50 flex items-center justify-center text-4xs font-bold"><span class="hidden group-hover:inline"><i class="fa fa-minus opacity-50"/></span></button>
                <button @click="fullscreen = true" class="w-[0.9rem] h-[0.9rem] rounded-full border-2 border-black/50 bg-green-500/50 flex items-center justify-center text-4xs font-bold"><span class="hidden group-hover:inline"><i class="fa fa-plus opacity-50"/></span></button>
            </div>
        </div>
        <div v-if="!expanded" class="rounded-lg bg-gray-200 dark:bg-gray-900 p-4 mt-2 overflow-scroll max-h-[4rem]">
            <pre class="font-mono">{{ value[index] }}</pre>
        </div>
        <div v-show="expanded" class="space-y-4">
            <Field
                v-for="subfield in subfields"
                :key="subfield.name"
                :field="subfield"
                :collection="collection"
                v-model="value[index][subfield.name]"
            />
        </div>

        <Modal :active="fullscreen" @hide="fullscreen = false" max-width="max-w-4xl">
            <Field
                v-for="subfield in subfields"
                :key="subfield.name"
                :field="subfield"
                :collection="collection"
                v-model="value[index][subfield.name]"
            />
        </Modal>
    </div>
</template>

<script>
import Modal from '../components/layout/modal.vue'
import { defineAsyncComponent } from 'vue'
import {useModel} from './model'

export default {
    props: ['subfields','collection','modelValue','index'],
    components: { Field: defineAsyncComponent(() => import('./Field.vue')), Modal },
    data() {
        return {
            currentValue: null,
            expanded: true,
            fullscreen: false
        }
    },
	setup(props, { emit }) {
		return {
            value: useModel(props, emit, 'modelValue'),
		}
	},
}
</script>

<style lang="postcss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;600&display=swap');

input, label {
    @apply border border-gray-300 rounded outline-blue-400 dark:outline-blue-500/40 px-3 py-2 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-600
}

select {
    @apply dark:bg-transparent
}
</style>
